import React from "react";
import Fade   from 'react-reveal/Fade';

export default function CustomStep({ text, footnote, animateDirection, count }) {
    return (
        <Fade {...animateDirection}>
            <div className="bg-white border_radius_10 mx-4 xl:mx-2 my-4 shadow-card_border py-10 sm:h-custom_step w-full lg:w-contact_card md:flex md:flex-row items-center">
                <div className="border-b-2 md:border-r-2 border-gray-100 w-full sm:w-28 text-center py-10 sm:py-0 sm:h-custom_step">
                    <p className="text-5xl font-bold text-lk_grey_one sm:w-28 sm:py-16">{count}</p>
                </div>
                <div className="py-6 sm:py-0 px-6">
                    <p className="text-sm sm:text-lg text-lk_grey_two">{text}</p>
                    {footnote && (
                        <p className="text-lg sm:text-4xl text-lk_red font-bold pt-2">{footnote}</p>
                    )}
                </div>
            </div>
        </Fade>
    );
}
