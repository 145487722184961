import { SIGN_IN_USER, SIGN_OUT_USER, SIGN_IN_TOKEN } from "./authConstants";
import { APP_LOADED } from "../../app/async/asyncReducer";
export const UPDATE_USER_DISPLAY_SETTINGS = "UPDATE_USER_DISPLAY_SETTINGS";

export function signInUser(user) {
    return (dispatch) => {
        dispatch({
            type: SIGN_IN_USER,
            payload: user,
        });
    };
}

export function signInToken(user) {
    return (dispatch) => {
        dispatch({
            type: SIGN_IN_TOKEN,
            payload: user,
        });
    };
}

export function verifyAuth() {
    return function (dispatch) {
        dispatch({ type: APP_LOADED });
    };
}

export function signOutUser() {
    return (dispatch) => {
        dispatch({
            type: SIGN_OUT_USER,
        });
    };
}

export const updateUserDisplaySettings = (updatedFields) => ({
    type: UPDATE_USER_DISPLAY_SETTINGS,
    payload: updatedFields,
});
