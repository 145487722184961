import React from "react";
// import CatalogueCTA from "../../../app/common/elements/CatalogueCTA";
import CustomHeader from "../../../app/common/elements/CustomHeader";
import ServicesImageCard from "../../../app/common/elements/ServicesImageCard";

export default function RepackingPage() {
    return (
        <div className="bg-lk_white_backround">
            <CustomHeader
                heading="Repacking"
                subHeading="Services"
                breadcrumbs={[
                    { name: "Home", href: "/", count: 1 },
                    { name: "Services", href: "/services", count: 2 },
                    {
                        name: "Repacking",
                        href: "/services/repacking",
                        count: 3,
                    },
                ]}
            />

            <p className="text-lk_grey_one text-2xl sm:text-3xl lg:text-5xl mx-auto my-12 text-center px-4 max-w-xs sm:max-w-2xl lg:max-w-7xl">
                Lakato has an extensive and stringent quality management system
                in place that is applied to both wholesaling and distribution
                activities and ensures compliance with South African
                legislation.
            </p>

            <ServicesImageCard
                image="/images/services/lakato_repackaging_v2.jpg"
                imageAlt="Lakato Repacking"
                textOne={
                    "Warehousing and Distribution activities for a number of Animal Health companies are carried out from the Lakato Johannesburg registered facility. Although Lakato JHB is not a registered manufacturing and packing facility for Act 101 medicines, the Lakato JHB Distribution Warehouse does carry out repacking activities on “Act 36” products for some of their distribution clients."
                }
            />

            <div className="pb-16 xl:pb-36"></div>
        </div>
    );
}
