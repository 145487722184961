import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import CustomButton from "../../app/common/form/CustomButton";
import CustomText from "../../app/common/form/CustomText";
import { useDispatch } from 'react-redux';
import { closeModal } from "../../app/common/modals/modalReducer";

export default function ResetPassword() {
    const [show, setShow] = useState(false);
    const { token } = useParams();
    const dispatch = useDispatch();

    dispatch(closeModal());

    return (
        <div className="max-w-md mx-auto mt-10 text-center">
            <h1 class="text-2xl sm:text-3xl lg:text-5xl mb-6">Rest your password</h1>

            <Formik
                initialValues={{
                    password: "",
                    password_confirm: "",
                    show_password: false,
                }}
                validationSchema={Yup.object().shape({
                    password: Yup.string().required('Password is required'),
                    password_confirm: Yup.string().oneOf([Yup.ref('password'), null], "Passwords don't match").required('Confirm Password is required'),
                })}
                onSubmit={async (
                    values,
                    { setSubmitting, setErrors, resetForm }
                ) => {
                    await axios({
                        method: "post",
                        url: `${process.env.REACT_APP_API_URL}reset-password`,
                        data: {
                            token: token,
                            password: values.password,
                            password_confirm: values.password_confirm
                        },
                    })
                        .then(() => {
                            // toast(
                            //     <NotificationToast
                            //         title={
                            //             "Password reset succesfull"
                            //         }
                            //         message={
                            //             "You can use your new password to login"
                            //         }
                            //     />
                            // );
                            setSubmitting(false);
                            resetForm();
                            window.location.href = '/';
                        })
                        .catch((error) => {
                            console.log(error);
                            console.log(error.response);

                            if (error.response.data === 'Invalid token') {
                                alert('Something went wrong, please try again!');
                            }
                        });
                }}
            >
                {({
                    values,
                    errors,
                    setFieldValue,
                    handleSubmit,
                    isValid,
                    isSubmitting,
                    dirty,
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Field
                            name="password"
                            label="Password"
                            as={CustomText}
                            type={show ? 'text' : 'password'}
                        />
                        <Field
                            name="password_confirm"
                            label="Confirm Password"
                            as={CustomText}
                            type={show ? 'text' : 'password'}
                        />
                        <button type="button" onClick={() => show ? setShow(false) : setShow(true)} className="text-xs text-gray-800">Show / Hide Password</button>
                        {Object.keys(errors).length > 0 &&
                            Object.keys(errors).map((key) => (
                                <p key={key} className="col-span-1 text-sm  text-red-400 font-light">
                                    <span>{errors[key]}</span>
                                </p>
                            ))
                        }
                        <div className="py-8 flex space-x-2">
                            <CustomButton
                                disabled={isSubmitting}
                                submitBool={isSubmitting}
                                type="submit"
                                text="Reset Password"
                                className="lkbutton bg-lk_red hover:bg-lk_red_hover w-32 sm:w-96 border_radius_10"
                            />

                            <p className="uppercase text-sm"><a href="/" className="underline">BACK TO LOGIN</a></p>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
