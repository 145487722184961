import React from "react";
import { useDispatch } from "react-redux";
import Fade from 'react-reveal/Fade';
import { openModal } from "../../../app/common/modals/modalReducer";

export default function CatalogueCTA() {
    const dispatch = useDispatch();
    return (        
        <Fade left cascade>
            <div className="bg-lk_grey_background_light py-12 sm:py-24 sm:flex h-h-96 sm:h-cta_medium_height xl:h-cta_height">
                <img 
                    className="w-1/2 2xl:w-1/3"
                    src="/images/laptopPlaceholder.jpg" 
                    alt="Lakato Laptop Placeholder"
                />
                <div className="max-w-xs px-4 sm:max-w-2xl lg:max-w-7xl mx-auto pt-8 xl:pt-12 sm:pt-0">
                    <h2 className="text-lk_grey_two max-w-lg text-2xl sm:text-3xl lg:text-5xl">
                        Get access to our huge catalogue of Veterinary Products
                    </h2>
                    <p className="pt-4 pb-6 lg:py-6 lg:m-auto max-w-xs lg:max-w-6xl 2xl:max-w-2xl text-sm sm:text-md xl:text-lg">
                    Lakato supplies a comprehensive range of Animal Health products via its national branch network to Veterinary practices, Veterinarian owned retail outlets and professional pet retail businesses throughout South Africa and Southern Africa.
                    </p>
                    <div className="flex justify-start">
                        <button 
                            className={"lkbutton w-32 lg:w-48 border_radius_4 bg-lk_red hover:bg-lk_red_hover mr-5 text-sm sm:text-md xl:text-lg"}
                        >
                            Register
                        </button>
                        <button 
                            className={"lkbutton w-32 lg:w-48 border_radius_4 bg-lk_grey_button hover:bg-lk_dark_grey_button text-sm sm:text-md xl:text-lg"}
                            onClick={() => dispatch(openModal({modalType: "ModalLogin"}))}
                        >
                            Login
                        </button>
                    </div>
                </div>
            </div>
        </Fade>
    );
}