import { Formik, Form } from "formik";
import axios from "axios";
import * as Yup from "yup";
import CustomButton from "../../../app/common/form/CustomButton";
import CustomText from "../../../app/common/form/CustomText";
import AdminSearchForm from "../../forms/AdminSearchForm";
import AdminNav from "../../nav/AdminNav";
import { useDispatch, useSelector } from "react-redux";
import CustomTextArea from "../../../app/common/form/CustomTextArea";
import { useState } from "react";
import { useEffect } from "react";
import { openModal } from "../../../app/common/modals/modalReducer";
import { signInUser } from "../../auth/authActions";
import { useHistory } from "react-router";
import { current } from "@reduxjs/toolkit";

export function SelectAccount() {
    const dispatch = useDispatch();
    let history = useHistory();
    const { currentUser } = useSelector((state) => state.auth);
    const [allAccounts, setAllAccounts] = useState([]);
    const [allFilteredAccounts, setAllFilteredAccounts] = useState([]);
    const [searchedAccount, setSearchedAccount] = useState("");
    const [guestLogin, setGuestLogin] = useState(false);

    useEffect(() => {
        getAccountDetials();
    }, [guestLogin]);

    async function getAccountDetials() {
        let specialList = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}get-accounts`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
            },
            data: {
                email: currentUser.email,
                guestLogin: guestLogin,
            },
        });

        let allSpecials = specialList.data;
        setAllAccounts(allSpecials);
        setAllFilteredAccounts(allSpecials);
    }

    async function setNewAccount(accCode, branch, classCust, accName, CustomerOnHold, ShipToAddr1) {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}account-login-switch`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
            },
            data: {
                user_id: currentUser.id,
                customer_code: accCode,
            },
        });

        let payload = {
            email: currentUser.email,
            token: currentUser.token,
            logged_in_at: currentUser.logged_in_at,
            name: accName,
            last_name: currentUser.last_name,
            id: currentUser.id,
            profile_image: currentUser.profile_image,
            isAdmin: currentUser.isAdmin,
            isCustomer: currentUser.isCustomer,
            customer_code: accCode,
            branch: branch,
            CustomerClass: classCust,
            CustomerOnHold: CustomerOnHold,
            multi_account: currentUser.multi_account,
            ShipToAddr1: ShipToAddr1,
            see_stock: currentUser.see_stock,
            warehouse99: currentUser.warehouse99,
            price_list: currentUser.price_list,
            paym8_privacy: currentUser.paym8_privacy,
            guest_login: currentUser.guest_login,
            supplier_stock: currentUser.supplier_stock,
            allowed_supplier: currentUser.allowed_supplier,
            display_img: currentUser.display_img,
            display_code: currentUser.display_code,
            display_desc: currentUser.display_desc,
            display_barCode: currentUser.display_barCode,
        };

        dispatch(signInUser(payload));
        history.push("/shop");
    }

    async function setAdminAccount() {
        //Get admin details again
        let old_user = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}back-to-admin`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
            },
            data: {
                email: currentUser.email,
            },
        });

        //Log back in as admin
        let payload = currentUser;
        payload.isLakato = old_user.data.is_lakato;
        payload.isSales = old_user.data.is_sales;
        payload.customer_code = old_user.data.customer_code;
        payload.branch = old_user.data.branch;
        payload.name = old_user.data.first_name;
        payload.last_name = old_user.data.last_name;
        payload.allowed_supplier = old_user.data.allowed_supplier;

        delete payload.main_admin;
        delete payload.CustomerClass;
        delete payload.CustomerOnHold;
        delete payload.ShipToAddr1;

        dispatch(signInUser(payload));
    }

    async function filterSearch(accounts, search) {
        if (search === "") {
            setAllFilteredAccounts(accounts);
        } else {
            let filteredData = accounts.filter((element) => {
                if (element.ShipToAddr1.toUpperCase().includes(search.toUpperCase()) || element.Customer.includes(search.toUpperCase())) {
                    return element;
                } else {
                    return null;
                }
            });
            setAllFilteredAccounts(filteredData);
        }
    }

    return (
        <div className="bg-white">
            <div className="container mx-auto mt-10">
                <div className="flex">
                    <h2>Please Select the Account you will be shopping on:</h2>
                    <input
                        type="text"
                        className="ml-5 w-96 rounded -mt-2"
                        onChange={(v) => {
                            setSearchedAccount(v.target.value);
                            filterSearch(allAccounts, v.target.value);
                        }}
                        placeholder="Account Search..."
                    />

                    {guestLogin === false && currentUser.guest_login === 1 && (
                        <CustomButton type="button" text="Guest Login" className="lkbutton bg-lk_red hover:bg-lk_red_hover border_radius_10 ml-5 w-28" onClick={() => setGuestLogin(true)} />
                    )}

                    {currentUser.isAdmin === 1 && (
                        <CustomButton type="button" text="Back to Admin" className="lkbutton bg-lk_red hover:bg-lk_red_hover border_radius_10 ml-5 w-36" onClick={() => setAdminAccount()} />
                    )}
                </div>
                <br />

                <div className="flex flex-wrap">
                    {allFilteredAccounts.map((account) => (
                        <>
                            <div className="mx-2">
                                <CustomButton
                                    type="submit"
                                    text={account.ShipToAddr1 + " - " + account.Customer}
                                    className={
                                        account.orders_count > 0
                                            ? "lkbutton bg-green-400 hover:bg-green-600 w-full border_radius_10 mb-5 ml-4"
                                            : "lkbutton bg-lk_red hover:bg-lk_red_hover w-full border_radius_10 mb-5 ml-4"
                                    }
                                    onClick={() => setNewAccount(account.Customer, account.Branch, account.CustomerClass, account.Name, account.CustomerOnHold, account.ShipToAddr1)}
                                />
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </div>
    );
}
