import React, { Fragment, useEffect, useState } from 'react'
// import { useHistory } from "react-router-dom";
import { closeModal } from "../../app/common/modals/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCheckCircle, faTimes } from "@fortawesome/pro-regular-svg-icons";
// import { Dialog, Transition } from '@headlessui/react';
import { faExclamation, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import CustomButton from '../../app/common/form/CustomButton';
import axios from 'axios';
import CustomText from '../../app/common/form/CustomText';

export default function ScriptHelp(props) {
    const dispatch = useDispatch();
    const { currentUser } = useSelector((state) => state.auth);

    let products = props.products;

    async function createScriptTemplate(products) {

        let script = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}create-script-template`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                userId: currentUser.id,
                name: currentUser.name + " " + currentUser.last_name,
                //   name: currentUser.ShipToAddr1,
                customer_code: currentUser.isCustomer === 1 && currentUser.customer_code !== null ? currentUser.customer_code : currentUser.customer_code === null || currentUser.customer_code.split(",").length > 1 ? "None" : currentUser.customer_code,
                orderItems: products,
            },
        });
        window.open(process.env.REACT_APP_URL + "Script/" + script.data, '_blank').focus();

    }


    return (
        <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 transition ease-in duration-300"
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                </div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="
                        inline-block align-middle bg-white rounded-lg px-4 py-5 pt-5 pb-4 
                        overflow-hidden shadow-xl transform transition-all
                    "
                >
                    <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                        <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                            onClick={() => dispatch(closeModal())}
                        >
                            <span className="sr-only">Close</span>
                            <FontAwesomeIcon className="text-lk_red text-2xl hover:text-lk_red_hover" aria-hidden="true" icon={faTimesCircle} />
                        </button>
                    </div>
                    <div>
                        <div className="mt-3 text-center sm:mt-16">
                            <h1 className="text-lg sm:text-2xl lg:text-4xl uppercase">
                                Script required
                            </h1>
                            <div className="mt-2 sm:px-32 mt-12 mb-5">
                                <div className="mt-2 grid grid-cols-1 gap-4">
                                    <div>


                                        <CustomButton
                                            // disabled={isSubmitting}
                                            // submitBool={isSubmitting}
                                            type="submit"
                                            text="Print Script Template"
                                            className="lkbutton bg-lk_red hover:bg-lk_red_hover w-72 border_radius_10 mb-5 mr-10"
                                            // onClick={() => PlaceThisOrder(products)}
                                            onClick={() => createScriptTemplate(products)}
                                        />

                                        <CustomButton
                                            // disabled={isSubmitting}
                                            // submitBool={isSubmitting}
                                            type="submit"
                                            text="Upload Script"
                                            className="lkbutton bg-blue-500 hover:bg-blue-700 w-72 border_radius_10 mb-5 mr-10"
                                            // onClick={() => PlaceThisOrder(products)}
                                            onClick={() => document.getElementById("uploadFile").click()}
                                        />

                                        <CustomButton
                                            // disabled={isSubmitting}
                                            // submitBool={isSubmitting}
                                            type="submit"
                                            text="Send Us the Script Later"
                                            className="lkbutton bg-blue-500 hover:bg-blue-700 w-72 border_radius_10 mb-5 mr-10"
                                            // onClick={() => PlaceThisOrder(products)}
                                            onClick={() => {
                                                props.setScriptUploaded('None');
                                                dispatch(closeModal());
                                            }}
                                        />
                                    </div>
                                </div>

                                <br /><br />



                                <br /><br />
                                <div>
                                    <div>
                                        <CustomButton
                                            type="button"
                                            text="Close"
                                            className="lkbutton bg-gray-400 hover:bg-gray-600 w-60 border_radius_10 "
                                            onClick={() => dispatch(closeModal())}
                                        />
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}