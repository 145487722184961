import React, { Fragment, useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import { closeModal } from "../../app/common/modals/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCheckCircle, faTimes } from "@fortawesome/pro-regular-svg-icons";
// import { Dialog, Transition } from '@headlessui/react';
import {
    faExclamation,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import CustomButton from "../../app/common/form/CustomButton";
import axios from "axios";
import CustomText from "../../app/common/form/CustomText";

export default function ShowPromotion(props) {
    // let history = useHistory();
    const dispatch = useDispatch();
    const [open] = useState(true);
    const { currentUser } = useSelector((state) => state.auth);
    const [cartTotal, setCartTotal] = useState([]);
    const [products, setProducts] = useState([]);
    // const [total, setTotal] = useState("1");
    // const [maxTotal, setMaxTotal] = useState("");
    // const [purchaseTotal, setPurchaseTotal] = useState(0);
    // const [freeTotal, setFreeTotal] = useState(0);

    const [discountType, setDiscountType] = useState("None");

    // const [specialQty, setSpecialQty] = useState(0);
    // const [specialType, setSpecialType] = useState(0);
    // const [specialSize, setSpecialSize] = useState(0);

    // const [currentPromotionTotal, setCurrentPromotionTotal] = useState([]);
    // const [currentPromotionSC, setCurrentPromotionSC] = useState([]);
    // const [currentSelected, setCurrentSelected] = useState(0);

    // const [buttonDisabled, setButtonDisabled] = useState(true);

    // let disabledCss = "lkbutton bg-red-600 w-60 border_radius_10 opacity-50 cursor-not-allowed";
    // let activeCss = "lkbutton bg-red-600 hover:bg-red-800 w-60 border_radius_10 disabled:opacity-50";

    let promotion = props;

    useEffect(() => {
        setCartTotal(
            parseFloat(window.sessionStorage.getItem("itemsInBasket"))
        );
        getProductsSpecial();
    }, []);

    async function getProductsSpecial() {
        let specialList = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}active-specials`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
            },
            data: {
                customer_code:
                    currentUser.isCustomer === 1 &&
                    currentUser.customer_code !== null
                        ? currentUser.customer_code
                        : currentUser.customer_code === null ||
                          currentUser.customer_code.split(",").length > 1
                        ? "None"
                        : currentUser.customer_code,
            },
        });
        let allSpecials = specialList.data;
        // let specialData = []

        await Object.keys(allSpecials).forEach(async function (key) {
            if (allSpecials[key].id == props.PromotionId) {
                let loopItems = allSpecials[key].items;
                let products = [];

                setDiscountType(allSpecials[key].discount_type);

                await Object.keys(loopItems).forEach(async function (index) {
                    if (loopItems[index].product_type === 1) {
                        products.push(loopItems[index].product_stock_code);
                    }
                });

                let specialList = await axios({
                    method: "post",
                    url: `${process.env.REACT_APP_API_URL}special-products`,
                    headers: {
                        Authorization: `Bearer ${currentUser.token}`,
                    },
                    data: {
                        // productsReq: products,
                        // productsFree: products,
                        products: [products].flat(),
                        customer_code:
                            currentUser.isCustomer === 1 &&
                            currentUser.customer_code !== null
                                ? currentUser.customer_code
                                : currentUser.customer_code === null ||
                                  currentUser.customer_code.split(",").length >
                                      1
                                ? "None"
                                : currentUser.customer_code,
                        branch: currentUser.branch,
                    },
                });

                // console.log(specialList.data)

                setProducts(specialList.data);

                // setProducts({col0: allSpecials[key].id,
                //     col1: allSpecials[key].promotion_name,
                //     col2: allSpecials[key].from_date,
                //     col3: allSpecials[key].to_date,
                //     col4: allSpecials[key].discount_type,
                //     col5: allSpecials[key].free_amount,
                //     col6: allSpecials[key].discount_percentage,
                //     col7: allSpecials[key].special_type,
                //     col8: allSpecials[key].req_buy_amount,
                //     col9: allSpecials[key].free_product_type,
                //     col10: allSpecials[key].limit_on_order,
                //     col11: allSpecials[key].limit_per_day,
                //     col12: allSpecials[key].special_on,
                //     col13: allSpecials[key].ProductsGroup,
                //     col14: allSpecials[key].rules
                // })
            }
        });
    }

    return (
        <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 transition ease-in duration-300"
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                </div>

                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>

                <div
                    className="
                        inline-block align-middle bg-white rounded-lg px-4 py-5 pt-5 pb-4 
                        overflow-hidden shadow-xl transform transition-all
                    "
                >
                    <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                        <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                            onClick={() => dispatch(closeModal())}
                        >
                            <span className="sr-only">Close</span>
                            <FontAwesomeIcon
                                className="text-lk_red text-2xl hover:text-lk_red_hover"
                                aria-hidden="true"
                                icon={faTimesCircle}
                            />
                        </button>
                    </div>
                    <div>
                        <div className="mt-3 text-center sm:mt-16">
                            <h1 className="text-lg sm:text-2xl lg:text-4xl uppercase">
                                {/* {promotion.col1} */}
                            </h1>
                            <div className="sm:px-32 mt-12 mb-5">
                                <div className="mt-2 grid grid-cols-1 gap-4">
                                    <div>
                                        <table className="border-collapse border border-slate-400">
                                            <thead>
                                                <tr>
                                                    {/* {promotion.col7 == 1 || promotion.col13 == 2 ? 
                                                        <th className="border border-slate-300">Select</th> 
                                                    : ""} */}
                                                    {/* <th className="border border-slate-300">Buy Amount</th> */}
                                                    <th className="border border-slate-300">
                                                        Stock Code
                                                    </th>
                                                    <th className="border border-slate-300">
                                                        Description
                                                    </th>
                                                    <th className="border border-slate-300">
                                                        Price Per Unit
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {products.map(
                                                    (product, index) => (
                                                        <>
                                                            {/* {(promotion.col11).includes(product.StockCode) ?  */}
                                                            <tr>
                                                                <td className="border border-slate-300 px-5 w-56 h-20">
                                                                    {
                                                                        product.StockCode
                                                                    }
                                                                </td>
                                                                <td className="border border-slate-300 px-2">
                                                                    {
                                                                        product.Description
                                                                    }{" "}
                                                                    {
                                                                        product.LongDesc
                                                                    }
                                                                </td>
                                                                <td className="border border-slate-300 px-2">
                                                                    {discountType !==
                                                                    1 ? (
                                                                        <>
                                                                            R{" "}
                                                                            {(
                                                                                parseFloat(
                                                                                    product.InvPrice
                                                                                ) -
                                                                                parseFloat(
                                                                                    product.InvPrice
                                                                                ) *
                                                                                    parseFloat(
                                                                                        "0." +
                                                                                            (parseFloat(
                                                                                                promotion.col6
                                                                                            ) >
                                                                                            9
                                                                                                ? promotion.col6
                                                                                                : "0" +
                                                                                                  promotion.col6)
                                                                                    )
                                                                            ).toFixed(
                                                                                2
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <strike className="text-gray-400">
                                                                                R{" "}
                                                                                {parseFloat(
                                                                                    product.InvPrice
                                                                                ).toFixed(
                                                                                    2
                                                                                )}
                                                                            </strike>
                                                                            <br />
                                                                            R{" "}
                                                                            {(
                                                                                parseFloat(
                                                                                    product.InvPrice
                                                                                ) -
                                                                                parseFloat(
                                                                                    product.InvPrice
                                                                                ) *
                                                                                    parseFloat(
                                                                                        "0." +
                                                                                            (parseFloat(
                                                                                                promotion.col6
                                                                                            ) >
                                                                                            9
                                                                                                ? promotion.col6
                                                                                                : "0" +
                                                                                                  promotion.col6)
                                                                                    )
                                                                            ).toFixed(
                                                                                2
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            {/* : ""} */}
                                                        </>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <br />
                                <br />

                                <br />
                                <br />
                                <div>
                                    <div>
                                        <CustomButton
                                            type="button"
                                            text="Close"
                                            className="lkbutton bg-gray-400 hover:bg-gray-600 w-60 border_radius_10 "
                                            onClick={() =>
                                                dispatch(closeModal())
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
