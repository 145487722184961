import React from "react";
import CatalogueCTA from "../../app/common/elements/CatalogueCTA";
import CustomHeader from "../../app/common/elements/CustomHeader";
import ProductCard from "../../app/common/products/ProductCard";

export default function HomePage() {
    const products = [
        {
            image: "/images/icons/animal.png",
            text: "Companion Animal Pharmaceuticals",
            href: "/shop-companion-animal-pharmaceuticals",
        },
        {
            image: "/images/icons/cold_chain.png",
            text: "Cold chain Biologicals",
            href: "/shop-cold-chain-biologicals",
        },
        {
            image: "/images/icons/premium_pet_food.png",
            text: "Premium Pet Foods",
            href: "/shop-premium-pet-foods",
        },
        {
            image: "/images/icons/large_animal.png",
            text: "Large Animal Pharmaceuticals",
            href: "/shop-large-animal-pharmaceuticals",
        },
        {
            image: "/images/icons/veterinary.png",
            text: "Veterinary retail products",
            href: "/shop-veterinary-retail-products",
        },
        {
            image: "/images/icons/poultry.png",
            text: "Poultry Pharmaceuticals",
            href: "/shop-poultry-pharmaceuticals",
        },
        {
            image: "/images/icons/pet_accessories.png",
            text: "Pet Toys and Accessories",
            href: "/shop-pet-accessories",
        },
        {
            image: "/images/icons/exotic_animal.png",
            text: "Exotic Animal Pharmaceuticals",
            href: "/products",
        },
        {
            image: "/images/icons/pharmaceuticals.png",
            text: "Human products used in Veterinary practice",
            href: "/shop-human-pharmaceuticals-used-in-veterinary-practice",
        },
        {
            image: "/images/icons/disposable.png",
            text: "Disposables",
            href: "/shop-disposables",
        },
        {
            image: "/images/icons/diagnostics.png",
            text: "Diagnostics",
            href: "/shop-diagnostics",
        },
        {
            image: "/images/icons/surgical.png",
            text: "Surgicals",
            href: "/shop-surgicals",
        },
        {
            image: "/images/icons/instrument.png",
            text: "Instruments",
            href: "/shop-instruments",
        },
        {
            image: "/images/icons/equine.png",
            text: "Equine Pharmaceuticals",
            href: "/shop-equine-pharmaceuticals",
        },
        {
            image: "/images/icons/exotic_premium_foods.png",
            text: "Exotic Premium Foods, Toys and Accessories",
            href: "/products",
        },
        {
            image: "/images/icons/wildlife.png",
            text: "Game Pharmaceuticals",
            href: "/shop-wildlife-pharmaceuticals",
        },
    ];

    return (
        <div className="bg-lk_grey_background_products">
            <CustomHeader
                image="/images/home_banner_background.jpg"
                heading="Products"
                breadcrumbs={[
                    { name: "Home", href: "/", count: 1 },
                    { name: "Products", href: "/products", count: 2 },
                ]}
            />

            <div className="max-w-7xl mx-auto">
                <p className="text-center pt-9 pb-12 m-auto max-w-5xl md:max-w-2xl lg:max-w-4xl xl:max-w-5xl text-lk_grey_one text-2xl sm:text-3xl lg:text-5xl">
                    Lakato carries the broadest range of Veterinary products possible. In essence, Lakato provides a <span className="text-lk_red font-bold">One Stop Shop</span> to customers in the
                    Animal Health Industry.
                </p>

                <p className="text-lg sm:text-2xl lg:text-4xl text-center py-8">Products include the likes of:</p>

                <div className="flex flex-wrap justify-center xl:justify-start">
                    {products.map((product) => (
                        <ProductCard key={product.text} image={product.image} text={product.text} link={product.link} href={"/shop"} />
                    ))}
                </div>
            </div>
            <div className="my-28" />

            <CatalogueCTA />
        </div>
    );
}
