import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import AdminSearchForm from "../../../forms/AdminSearchForm";
import AdminNav from "../../../nav/AdminNav";
import { useTable, usePagination } from 'react-table'
import Select from 'react-select'
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../app/common/modals/modalReducer";
import ErrorBoundary from "../../../../app/layout/ErrorBoundary";

export default function Promotions(props) {

  let history = useHistory();
  let dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);

  const [cartTotal, setCartTotal] = useState([])
  const [specials, setSpecials] = useState([])

  if(currentUser.multi_account === true && (currentUser.customer_code == null || currentUser.customer_code == "None" || currentUser.customer_code.split(",").length > 1) ){
    window.location.replace('/select-profile')
}

  useEffect(() => {
    setCartTotal(parseFloat(window.sessionStorage.getItem("itemsInBasket")));
    getActivePromotions();
  }, []);

  async function getActivePromotions(){
    
    let specialList = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}active-specials`,
        headers: { 
            Authorization: `Bearer ${currentUser.token}`
        },
        data: {
          customer_code: currentUser.isCustomer === 1 && currentUser.customer_code !== null ? currentUser.customer_code : currentUser.customer_code === null || currentUser.customer_code.split(",").length > 1 ? "None" : currentUser.customer_code,
        },
    });

    let specialListInStock = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}active-specials-in-stock`,
      headers: { 
          Authorization: `Bearer ${currentUser.token}`
      },
      data: {
        customer_code: currentUser.isCustomer === 1 && currentUser.customer_code !== null ? currentUser.customer_code : currentUser.customer_code === null || currentUser.customer_code.split(",").length > 1 ? "None" : currentUser.customer_code,
        branch: currentUser.branch
      },
  });

  let SpecialInStock = specialListInStock.data;

    let allSpecials = specialList.data
    let specialData = []
    
    await Object.keys(allSpecials).forEach(async function (key) {

      let loopItems = allSpecials[key].items;
      let products = [];
      let free = [];
      let active = 0;

      await Object.keys(loopItems).forEach(async function (index) {
        if(loopItems[index].product_type == '1'){
            products.push(loopItems[index].product_stock_code)

            await Object.keys(SpecialInStock).forEach(async function(index2){
              if(allSpecials[key].id == SpecialInStock[index2][0].special_id){
                // console.log("ad")
                // console.log(SpecialInStock[index2])
                let itemData = SpecialInStock[index2];
                await Object.keys(itemData).forEach(async function(index3){
                  // console.log(itemData[index3].stock)
                  if(itemData[index3].stock == "Stock available"){
                    active = 1;
                  }
                })
              }
            })
        }
        if(loopItems[index].product_type == '2'){
            free.push(loopItems[index].product_stock_code)
        }
      })

      

        specialData.push({
            col0: allSpecials[key].id,
            col1: allSpecials[key].promotion_name,
            col2: allSpecials[key].from_date,
            col3: allSpecials[key].to_date,
            col4: allSpecials[key].discount_type,
            col5: allSpecials[key].free_amount,
            col6: allSpecials[key].discount_percentage,
            col7: allSpecials[key].special_type,
            col8: allSpecials[key].req_buy_amount,
            col9: allSpecials[key].free_product_type,
            col9: allSpecials[key].limit_on_order,
            col10: allSpecials[key].limit_per_day,
            col11: products,
            col12: free,
            col13: allSpecials[key].special_on,
            col14: allSpecials[key].ProductsGroup,
            col15: allSpecials[key].rules,
            col16: allSpecials[key].file_url,
            col17: allSpecials[key].promo_desc,
            col18: active,
        });
    });


    setSpecials(specialData)

  }

  // async function addItemToCart(itemId){
  //   let prevItemClicked = JSON.parse(window.sessionStorage.getItem("itemsClicked"));
  //   if(prevItemClicked !== null){
  //     window.sessionStorage.setItem("itemsClicked", JSON.stringify([...prevItemClicked, parseFloat(itemId)]));
  //   }else{
  //     window.sessionStorage.setItem("itemsClicked", JSON.stringify(parseFloat(itemId)));
  //   }

  //   let prevItemIds = JSON.parse(window.sessionStorage.getItem("itemsIdsInBasket"));

  //   if(prevItemIds !== null && !prevItemIds.includes(parseFloat(itemId))){
  //     window.sessionStorage.setItem("itemsIdsInBasket", JSON.stringify([...prevItemIds, parseFloat(itemId)]));

  //     let CurrentCartAmount = window.sessionStorage.getItem("itemsInBasket")
  //     let NewAmount = parseFloat(CurrentCartAmount) + 1
  //     window.sessionStorage.setItem("itemsInBasket", NewAmount);
  //     setCartTotal(NewAmount)

  //   }else{

  //     let totalItemsClicked = JSON.parse(window.sessionStorage.getItem("itemsClicked"));
  //     let thisItem = 0;

  //     await Object.keys(totalItemsClicked).forEach(function (key) {
  //       if(totalItemsClicked[key] === itemId){
  //         thisItem += 1
  //       }
  //     });

  //     let prevItemTotals = JSON.parse(window.sessionStorage.getItem("totalItemsInBasket"));
  //     if(prevItemTotals !== null){
  //       window.sessionStorage.setItem("totalItemsInBasket", JSON.stringify([...prevItemTotals, [thisItem, itemId]]));
  //     }else{
  //       window.sessionStorage.setItem("totalItemsInBasket", JSON.stringify([[thisItem, itemId]]));
  //     }

  //   }
  // }

    const data = React.useMemo(
        () => specials, [specials]
      )

      const columns = React.useMemo(
        () => [
          {
            Header: 'Description',
            accessor: 'col3',
            Cell: tableProps => (
                <div className="flex content-center items-center">
                    <p className="content-center justify-self-center ml-10">{tableProps.row.original.col1}</p>
                    <div className="bg-red-600 rounded-full w-14 justify-self-center ml-14">
                        <p className="uppercase text-white text-sm px-2 py-2">
                          {tableProps.row.original.col4 == "1" ? tableProps.row.original.col6 : tableProps.row.original.col5}
                          <br/>
                          {tableProps.row.original.col4 == "1" ? "%" : "FREE"}</p>
                    </div>
                </div>
              )
          },
          {
            Header: '',
            accessor: 'col4',
            Cell: rowInfo => {
              return (
                <>
                {rowInfo.row.original.col18 == 0 ? 
                  <>
                    <button className="px-10 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one uppercase mx-auto cursor-not-allowed opacity-25">
                      Out Of Stock
                    </button>
                  </> 
                :
                <button className="px-10 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto" onClick={() => {dispatch(openModal({modalType: rowInfo.row.original.col4 == "1" ? "PromotionDiscount" : "PromotionFree", modalProps: {Promotion: rowInfo.row.original}}))}}>
                  View Promotion
                </button>
                }
                </>
              )
            }
          },
        ],
        []
      )

      const tableInstance = useTable({ columns, data }, usePagination)

      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = tableInstance

    return(
        <div className="bg-gray-50">
            <AdminSearchForm
                heading="Promotions"
                color="text-gray-500"
                header_color="bg-gradient-to-b from-gray-300"
                crumb_color="text-black"
                breadcrumbs={[
                    { name: "Home", href: "/", count: 1 },
                    { name: "Promotions", href: "/promotions", count: 2 },
                ]}
            />

            {/* <AdminNav cartTotal={cartTotal}/> */}
            <br/><br/>

            <div className="container mx-auto flex">

            <ErrorBoundary>
                <table {...getTableProps()} className="mb-10 w-full" style={{borderCollapse: "separate", borderSpacing: "0 1em"}}>
                    <thead>
                    {
                    headerGroups.map(headerGroup => (
                        
                        <tr {...headerGroup.getHeaderGroupProps()} className="bg-gray-100">
                        {
                        headerGroup.headers.map(column => (
                            
                            <th {...column.getHeaderProps()} className="px-5 py-5 font-light">
                            {column.render('Header')}
                            </th>
                        ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {
                    rows.map(row => {
                        
                        prepareRow(row)
                        return (
                        
                        <tr {...row.getRowProps()} className="bg-white rounded mt-5">
                            {
                            row.cells.map(cell => {
                            
                            return (
                                <td {...cell.getCellProps()} style={{borderRight: "1px solid #EFEFEF"}} className="mx-auto text-center relative">
                                    
                                    {(cell.render('Cell').props.cell.column.id === "col2" || cell.render('Cell').props.cell.column.id === "col4") ? <p className="text-center px-5 py-5 text-gray-500">{cell.render('Cell')}</p> : cell.render('Cell')}
                                </td>
                            )
                            })}
                        </tr>
                        )
                    })}
                    </tbody>
                </table>
              </ErrorBoundary>

            </div>

        </div>
    )
}