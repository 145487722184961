import { useState, useEffect, Fragment, useRef } from "react";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";

export function AdminProductTags() {
    const { currentUser } = useSelector((state) => state.auth);
    const [tags, setTags] = useState([]);
    const [open, setOpen] = useState(false);
    const cancelButtonRef = useRef(null);
    const [data, setData] = useState({});
    const [load_data, setLoadData] = useState(true);

    useEffect(() => {
        if (load_data == true) {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}get_product_tags_admin`,
                headers: { Authorization: `Bearer ${currentUser.token}` },
                data: {
                    userId: currentUser.id,
                },
            }).then((res) => {
                setTags(res.data);
            });

            setLoadData(false);
        }
    }, [load_data]);

    const openPopup = (tagCategory, type) => {
        if (!tagCategory.id) {
            setData({
                id: 0,
                name: "",
            });
        } else {
            setData(tagCategory);
        }

        setOpen(true);
    };

    const handleOnChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const submit = (e) => {
        e.preventDefault();

        //axios post to update tag category
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}save_product_tag_admin`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: data,
        }).then((res) => {
            console.log(res);
            setOpen(false);
            setLoadData(true);
        });
    };

    const deleteTag = (id) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Are you sure you wish to delete this tag?")) {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}delete_product_tag_admin`,
                headers: { Authorization: `Bearer ${currentUser.token}` },
                data: { id: id },
            }).then((res) => {
                setOpen(false);
                setLoadData(true);
            });
        }
    };

    return (
        <div className="bg-white">
            <div className="my-8 space-y-6 px-4 sm md:max-w-2xl lg:max-w-4xl xl:max-w-5xl m-auto">
                <div className="flex justify-between items-center">
                    <h1>Tags</h1>

                    <button
                        className="px-2 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase"
                        onClick={() => openPopup([])}
                    >
                        Add
                    </button>
                </div>

                <div className="border-b border-gray-200 w-full">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center">Tag</div>
                        <div className="flex items-center">Actions</div>
                    </div>
                </div>

                {tags?.map((tag) => (
                    <div key={tag.id}>
                        <div className="border-b border-gray-200 w-full">
                            <div className="flex justify-between items-center">
                                <div className="flex items-center">
                                    <p className="ml-2 text-lg">{tag.name}</p>
                                </div>
                                <div>
                                    <button
                                        className="px-2 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase"
                                        onClick={() => openPopup(tag)}
                                    >
                                        Edit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <Transition.Root show={open == true} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {}}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                                    <form onSubmit={submit}>
                                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6  py-8">
                                            <div className="sm:col-span-3">
                                                <label htmlFor="name">Tag Name</label>
                                                <input id="name" type="text" name="name" value={data.name} className="mt-1 block w-full" onChange={handleOnChange} placeholder="Tag Name" required />
                                            </div>
                                        </div>

                                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3">
                                            <button type="submit" className="lkbutton rounded-xl bg-lk_red hover:bg-lk_red_hover text-sm sm:text-md xl:text-lg">
                                                SAVE
                                            </button>

                                            {data.id > 0 && (
                                                <button
                                                    type="button"
                                                    className="inline-flex items-center justify-center w-full px-4 py-3 bg-red-400 text-body rounded-xl uppercase disabled:opacity-25 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 hover:bg-accent"
                                                    onClick={() => deleteTag(data.id)}
                                                >
                                                    DELETE
                                                </button>
                                            )}

                                            <button
                                                type="button"
                                                className="inline-flex items-center justify-center w-full px-4 py-3 bg-gray-400 text-body rounded-xl uppercase disabled:opacity-25 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 hover:bg-accent"
                                                onClick={() => setOpen(false)}
                                                ref={cancelButtonRef}
                                            >
                                                CANCEL
                                            </button>
                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
}
