import { useState, useEffect, Fragment, useRef } from "react";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";

export function AdminProductCategories() {
    const { currentUser } = useSelector((state) => state.auth);
    const [productCategories, setProductCategories] = useState([]);
    const [open, setOpen] = useState(false);
    const cancelButtonRef = useRef(null);
    const [data, setData] = useState({});
    const [load_data, setLoadData] = useState(true);

    useEffect(() => {
        if (load_data == true) {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}get_product_categories`,
                headers: { Authorization: `Bearer ${currentUser.token}` },
                data: {
                    userId: currentUser.id,
                },
            }).then((res) => {
                setProductCategories(res.data);
            });

            setLoadData(false);
        }
    }, [load_data]);

    const openPopup = (productCategory, type) => {
        if (type == "add_sub_category") {
            setData({
                id: 0,
                name: "",
                sort_order: 0,
                parent_category_id: productCategory.id,
                sub_category_id: 0,
                filter_type: "",
                filter_code: "",
            });
        } else if (type == "add_child_sub_category") {
            setData({
                id: 0,
                name: "",
                sort_order: 0,
                parent_category_id: productCategory.parent_category_id,
                sub_category_id: productCategory.id,
                filter_type: "",
                filter_code: "",
            });
        } else {
            setData(productCategory);
        }

        setOpen(true);
    };

    const handleOnChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const submit = (e) => {
        e.preventDefault();
        console.log(data);

        //axios post to update product category
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}save_product_category`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: data,
        }).then((res) => {
            console.log(res);
            setOpen(false);
            setLoadData(true);
        });
    };

    const filter_type_options = [
        { value: 1, label: "UserField1" },
        { value: 2, label: "AlternateKey2" },
        { value: 3, label: "ProductClass" },
    ];

    const deleteProductCategory = (id) => {
        // if (confirm("Are you sure you wish to delete this item?") && id != 0) {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}delete_product_category`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: { id: id },
        }).then((res) => {
            console.log(res);
            setOpen(false);
            setLoadData(true);
        });
        // }
    };

    return (
        <div className="bg-white">
            <div className="my-8 space-y-6 px-4 sm md:max-w-2xl lg:max-w-4xl xl:max-w-5xl m-auto">
                <div className="flex justify-between items-center">
                    <h1>Product Categories</h1>

                    <button
                        className="px-2 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase"
                        onClick={() => openPopup([], "add_category")}
                    >
                        Add
                    </button>
                </div>

                {productCategories
                    ?.filter((pc) => pc.parent_category_id == 0)
                    ?.map((productCategory) => (
                        <div key={productCategory.id} className="mb-4">
                            <div className="grid grid-cols-12 gap-4">
                                <div className="col-span-6">
                                    <div className="text-black">
                                        {productCategory.name} -{" "}
                                        {productCategory.filter_code}
                                        {" - "}
                                        {filter_type_options
                                            .filter(
                                                (option) =>
                                                    option.value ==
                                                    productCategory.filter_type
                                            )
                                            .map((option) => (
                                                <span key={option.value}>
                                                    {option.label}
                                                </span>
                                            ))}
                                    </div>
                                </div>

                                <div className="col-span-2 text-right pr-4">
                                    {productCategory.sort_order}
                                </div>

                                <div className="col-span-4">
                                    <div className="flex items-center space-x-2 justify-end">
                                        <button
                                            className="px-2 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase"
                                            onClick={() =>
                                                openPopup(
                                                    productCategory,
                                                    "add_sub_category"
                                                )
                                            }
                                        >
                                            Add Child
                                        </button>

                                        <button
                                            className="px-2 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase"
                                            onClick={() =>
                                                openPopup(
                                                    productCategory,
                                                    "edit"
                                                )
                                            }
                                        >
                                            Edit
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {productCategories
                                ?.filter(
                                    (pc) =>
                                        pc.parent_category_id ==
                                            productCategory.id &&
                                        pc.sub_category_id == 0
                                )
                                ?.map((productCategory) => (
                                    <div
                                        key={productCategory.id}
                                        className="mt-4"
                                    >
                                        <div className="grid grid-cols-12 gap-4">
                                            <div className="col-span-6">
                                                <div className="text-black ml-8">
                                                    {productCategory.name} -{" "}
                                                    {
                                                        productCategory.filter_code
                                                    }
                                                    {" - "}
                                                    {filter_type_options
                                                        .filter(
                                                            (option) =>
                                                                option.value ==
                                                                productCategory.filter_type
                                                        )
                                                        .map((option) => (
                                                            <span
                                                                key={
                                                                    option.value
                                                                }
                                                            >
                                                                {option.label}
                                                            </span>
                                                        ))}
                                                </div>
                                            </div>

                                            <div className="col-span-2 text-right">
                                                {productCategory.sort_order}
                                            </div>

                                            <div className="col-span-4 flex items-center space-x-2 justify-end">
                                                <button
                                                    className="px-2 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase"
                                                    onClick={() =>
                                                        openPopup(
                                                            productCategory,
                                                            "add_child_sub_category"
                                                        )
                                                    }
                                                >
                                                    Add Child
                                                </button>

                                                <button
                                                    className="px-2 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase"
                                                    onClick={() =>
                                                        openPopup(
                                                            productCategory,
                                                            "edit"
                                                        )
                                                    }
                                                >
                                                    Edit
                                                </button>
                                            </div>
                                        </div>

                                        {productCategories
                                            ?.filter(
                                                (pc) =>
                                                    pc.parent_category_id ==
                                                        productCategory.parent_category_id &&
                                                    pc.sub_category_id ==
                                                        productCategory.id
                                            )
                                            ?.map((productCategory2) => (
                                                <div
                                                    key={productCategory2.id}
                                                    className="mt-4"
                                                >
                                                    <div className="grid grid-cols-12 gap-4">
                                                        <div className="col-span-6">
                                                            <div className="text-black ml-16">
                                                                {
                                                                    productCategory2.name
                                                                }{" "}
                                                                -{" "}
                                                                {
                                                                    productCategory2.filter_code
                                                                }
                                                                {" - "}
                                                                {filter_type_options
                                                                    .filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            option.value ==
                                                                            productCategory2.filter_type
                                                                    )
                                                                    .map(
                                                                        (
                                                                            option
                                                                        ) => (
                                                                            <span
                                                                                key={
                                                                                    option.value
                                                                                }
                                                                            >
                                                                                {
                                                                                    option.label
                                                                                }
                                                                            </span>
                                                                        )
                                                                    )}
                                                            </div>
                                                        </div>

                                                        <div className="col-span-2 text-right">
                                                            {
                                                                productCategory2.sort_order
                                                            }
                                                        </div>

                                                        <div className="col-span-4 flex items-center space-x-2 justify-end">
                                                            <button
                                                                className="px-2 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase"
                                                                onClick={() =>
                                                                    openPopup(
                                                                        productCategory2,
                                                                        "edit"
                                                                    )
                                                                }
                                                            >
                                                                Edit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                ))}
                        </div>
                    ))}
            </div>

            <Transition.Root show={open == true} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRef}
                    onClose={() => {}}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                                    <form onSubmit={submit}>
                                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6  py-8">
                                            <div className="sm:col-span-3">
                                                <label htmlFor="name">
                                                    Category Name
                                                </label>
                                                <input
                                                    id="name"
                                                    type="text"
                                                    name="name"
                                                    value={data.name}
                                                    className="mt-1 block w-full"
                                                    onChange={handleOnChange}
                                                    placeholder="Category Name"
                                                    required
                                                />
                                            </div>

                                            <div className="sm:col-span-3">
                                                <label htmlFor="sort_order">
                                                    Sort Order
                                                </label>
                                                <input
                                                    id="sort_order"
                                                    type="number"
                                                    name="sort_order"
                                                    value={data.sort_order}
                                                    className="mt-1 block w-full"
                                                    onChange={handleOnChange}
                                                    placeholder="Sort Order"
                                                    required
                                                />
                                            </div>

                                            <div className="sm:col-span-3">
                                                <label htmlFor="name">
                                                    Filter Field
                                                </label>
                                                <select
                                                    id="filter_type"
                                                    name="filter_type"
                                                    value={data.filter_type}
                                                    className="mt-1 block w-full"
                                                    onChange={handleOnChange}
                                                >
                                                    <option value="">
                                                        Choose an option
                                                    </option>

                                                    {filter_type_options.map(
                                                        (option) => (
                                                            <option
                                                                key={
                                                                    option.value
                                                                }
                                                                value={
                                                                    option.value
                                                                }
                                                            >
                                                                {option.label}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </div>

                                            <div className="sm:col-span-3">
                                                <label htmlFor="name">
                                                    Filter Code
                                                </label>
                                                <input
                                                    id="filter_code"
                                                    type="text"
                                                    name="filter_code"
                                                    value={data.filter_code}
                                                    className="mt-1 block w-full"
                                                    onChange={handleOnChange}
                                                    placeholder="Syspro Code"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3">
                                            <button
                                                type="submit"
                                                className="lkbutton rounded-xl bg-lk_red hover:bg-lk_red_hover text-sm sm:text-md xl:text-lg"
                                            >
                                                SAVE
                                            </button>

                                            {/* delete button */}
                                            <button
                                                type="button"
                                                className="inline-flex items-center justify-center w-full px-4 py-3 bg-gray-400 text-body rounded-xl uppercase disabled:opacity-25 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 hover:bg-accent"
                                                onClick={() =>
                                                    deleteProductCategory(
                                                        data.id
                                                    )
                                                }
                                            >
                                                DELETE
                                            </button>

                                            <button
                                                type="button"
                                                className="inline-flex items-center justify-center w-full px-4 py-3 bg-gray-400 text-body rounded-xl uppercase disabled:opacity-25 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 hover:bg-accent"
                                                onClick={() => setOpen(false)}
                                                ref={cancelButtonRef}
                                            >
                                                CANCEL
                                            </button>
                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
}
