import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import AdminSearchForm from "../../../forms/AdminSearchForm";
import AdminNav from "../../../nav/AdminNav";
import { useTable, usePagination, useSortBy } from "react-table";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomButton from "../../../../app/common/form/CustomButton";
import { current } from "@reduxjs/toolkit";

export default function BackOrders(props) {
    let history = useHistory();
    const { currentUser } = useSelector((state) => state.auth);

    const [cartTotal, setCartTotal] = useState([]);
    const [orders, setOrders] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [recordCount, setRecordCount] = useState("0");
    const [sortBy, setSortBy] = useState("OrderDate");
    const [sortDirection, setSortDirection] = useState("asc");
    const [pageSizeValue, setPageSizeValue] = useState(10);

    const allowedSups =
        currentUser.supplier_stock != null
            ? currentUser.supplier_stock.split(",").map(function (item) {
                  return item.trim();
              })
            : currentUser.supplier_stock;

    if (
        currentUser.multi_account === true &&
        (currentUser.customer_code == null ||
            currentUser.customer_code == "None" ||
            currentUser.customer_code.split(",").length > 1)
    ) {
        window.location.replace("/select-profile");
    }

    useEffect(() => {
        setCartTotal(
            parseFloat(window.sessionStorage.getItem("itemsInBasket"))
        );
        getOrders(pageSizeValue);
    }, []);

    async function getOrders(pageSizeValue) {
        let orders = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}all-back-orders`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                userId: currentUser.id,
                customer_code:
                    currentUser.isCustomer === 1 &&
                    currentUser.customer_code !== null
                        ? currentUser.customer_code
                        : currentUser.customer_code === null ||
                          currentUser.customer_code.split(",").length > 1
                        ? "None"
                        : currentUser.customer_code,
                branch: currentUser.branch,
                pageNumber: pageNumber,
                pageSizeValue: pageSizeValue,
            },
        });

        console.log(orders);

        let orderList = orders.data[0];
        setRecordCount(orders.data[1][0][""]);

        // console.log(orderList)

        let allOrders = [];

        await Object.keys(orderList).forEach(function (key) {
            allOrders.push({
                col1: orderList[key].SalesOrder,
                col7: orderList[key].MStockCode,
                col3: orderList[key].MStockDes,
                col5: Math.floor(orderList[key].MOrderQty),
                col6: Math.floor(orderList[key].MBackOrderQty),
                col4: orderList[key].InvWarehouse,
                col25: orderList[key].InvwarehouseQty,
                col27: orderList[key].Supplier,
            });
        });

        setOrders(allOrders);
    }

    async function changePageSize(value) {
        setPageSizeValue(value);
        getOrders(value);
    }

    const data = React.useMemo(() => orders, [orders]);

    const columns = React.useMemo(
        () => [
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                    >
                        Order No.
                    </div>
                ),
                accessor: "col1",
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                    >
                        Stock Code
                    </div>
                ),
                accessor: "col7",
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                    >
                        Description
                    </div>
                ),
                accessor: "col3",
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                    >
                        Qty Ordered
                    </div>
                ),
                accessor: "col5",
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                    >
                        Qty on Backorder
                    </div>
                ),
                accessor: "col6",
                Cell: (tableProps) => (
                    <span className="text-red-600">
                        {tableProps.row.original.col6}
                    </span>
                ),
            },
            {
                Header: "Stock Status",
                accessor: "col4",
                Cell: (rowInfo) => {
                    return (
                        <>
                            {rowInfo.row.original.col4 === "Out of Stock" ? (
                                <p className="text-red-500">
                                    {rowInfo.row.original.col4}{" "}
                                    {currentUser.see_stock === 1 &&
                                    currentUser.supplier_stock !== null &&
                                    allowedSups.includes(
                                        rowInfo.row.original.col27
                                    ) ? (
                                        <>
                                            {"( "}
                                            {rowInfo.row.original.col25}
                                            {" )"}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </p>
                            ) : (
                                <p>
                                    {rowInfo.row.original.col4}{" "}
                                    {currentUser.see_stock === 1 &&
                                    currentUser.supplier_stock !== null &&
                                    allowedSups.includes(
                                        rowInfo.row.original.col27
                                    ) ? (
                                        <>
                                            {"( "}
                                            {rowInfo.row.original.col25}
                                            {" )"}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </p>
                            )}
                        </>
                    );
                },
            },
        ],
        []
    );

    const tableInstance = useTable({ columns, data }, useSortBy, usePagination);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        prepareRow,
        pageIndex,
        pageOptions,
        gotoPage,
        pageCount,
        pageSize,
        setPageSize,
    } = tableInstance;

    async function nextPageNavigate(pageNumber, pageSizeValue) {
        let pageNo = parseFloat(pageNumber);
        let newNo = pageNo + 1;

        setPageNumber(newNo);

        getOrders(pageSizeValue);
    }

    async function previousPageNavigate(pageNumber, pageSizeValue) {
        let pageNo = parseFloat(pageNumber);
        let newNo = pageNo - 1;

        setPageNumber(newNo);

        getOrders(pageSizeValue);
    }

    return (
        <div className="bg-gray-50">
            <AdminSearchForm
                heading="Orders"
                color="text-gray-500"
                header_color="bg-gradient-to-b from-gray-300"
                crumb_color="text-black"
                breadcrumbs={[
                    { name: "Home", href: "/", count: 1 },
                    { name: "Orders", href: "/orders", count: 2 },
                ]}
            />

            {/* <AdminNav cartTotal={cartTotal}/> */}
            <br />
            <br />

            <div className="container mx-auto">
                <small>*Click on item line to view full order</small>

                <table
                    {...getTableProps()}
                    className="mb-10 w-full"
                    style={{
                        borderCollapse: "separate",
                        borderSpacing: "0 1em",
                    }}
                >
                    <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                className="bg-gray-200"
                                key={index}
                            >
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps()
                                        )}
                                        className="px-2 py-5 font-light text-sm"
                                    >
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    className="bg-white mt-5 shadow-lg cursor-pointer"
                                    onClick={() =>
                                        history.push(
                                            "/order/" + row.original.col1
                                        )
                                    }
                                >
                                    {row.cells.map((cell, index) => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                style={{
                                                    borderRight:
                                                        "1px solid #EFEFEF",
                                                    textAlign: "left",
                                                }}
                                                className="mx-auto text-center relative px-2 py-2"
                                                key={index}
                                            >
                                                <p className="text-center px-5 py-5">
                                                    {cell.render("Cell")}
                                                </p>
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <nav
                    className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 bg-gray-50"
                    aria-label="Pagination"
                >
                    <div className="hidden sm:block">
                        <div className="flex">
                            <div>
                                <select
                                    value={pageSizeValue}
                                    onChange={(e) => {
                                        changePageSize(e.target.value);
                                        setPageSize(Number(e.target.value));
                                    }}
                                >
                                    {[10, 25, 50].map((pageSize) => (
                                        <option key={pageSize} value={pageSize}>
                                            Show {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm text-gray-700">
                                    Page{" "}
                                    <span className="font-medium">
                                        {parseFloat(pageNumber) + 1}
                                    </span>{" "}
                                    of{" "}
                                    <span className="font-medium">
                                        {Math.ceil(
                                            parseFloat(recordCount) /
                                                Number(pageSizeValue)
                                        )}
                                    </span>
                                </p>
                                <p className="text-sm text-gray-700">
                                    Showing{" "}
                                    <span className="font-medium">
                                        {parseFloat(pageNumber) === 0
                                            ? "1"
                                            : parseFloat(pageNumber) *
                                                  Number(pageSizeValue) +
                                              1}
                                    </span>{" "}
                                    to{" "}
                                    <span className="font-medium">
                                        {parseFloat(pageNumber) === 0
                                            ? pageSizeValue
                                            : parseFloat(pageNumber) *
                                                  Number(pageSizeValue) +
                                                  Number(pageSizeValue) >
                                              recordCount
                                            ? recordCount
                                            : parseFloat(pageNumber) *
                                                  Number(pageSizeValue) +
                                              Number(pageSizeValue)}
                                    </span>{" "}
                                    of{" "}
                                    <span className="font-medium">
                                        {recordCount}
                                    </span>{" "}
                                    results
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                        <button
                            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            onClick={() =>
                                previousPageNavigate(pageNumber, pageSizeValue)
                            }
                            disabled={pageNumber === 0}
                        >
                            Previous
                        </button>
                        <button
                            onClick={() =>
                                nextPageNavigate(pageNumber, pageSizeValue)
                            }
                            disabled={
                                parseFloat(pageNumber) * 10 + 10 > recordCount
                            }
                            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        >
                            Next
                        </button>
                    </div>
                </nav>
            </div>
        </div>
    );
}
