import React from "react";
// import CatalogueCTA from "../../../app/common/elements/CatalogueCTA";
import CustomHeader from "../../../app/common/elements/CustomHeader";
import ServicesImageCard from "../../../app/common/elements/ServicesImageCard";
import IconCard from "../../../app/common/elements/IconCard";
import Fade  from 'react-reveal/Fade';

export default function WarehousingDistributionPage() {
    const services = [
        {image: "/images/icons/checkboard.png", text: "Physical checking and receiving of all stock into the facility.", count: "02"},
        {image: "/images/icons/recepting.png", text: "Receipting of stock onto an agreed upon accounting system.", count: "03"},
        {image: "/images/icons/stock_counts.png", text: "Monthly stock counts and ongoing stock control.", count: "04"},
        {image: "/images/icons/support.png", text: "Telesales support, order processing and follow-up.", count: "05"},
        {image: "/images/icons/packing.png", text: "Picking and packing individual orders.", count: "06"},
        {image: "/images/icons/dispatching.png", text: "Dispatching of orders on either delivery notes or invoices.", count: "07"},
        {image: "/images/icons/handling.png", text: "Handling of any customer returns.", count: "08"},
        {image: "/images/icons/logistics.png", text: "Handling of any logistics related customer queries.", count: "09"},
        {image: "/images/icons/system.png", text: "Tracking of parcels and provision of proof of delivery.", count: "10"},
        {image: "/images/icons/participating.png", text: "Participation in the sampling process.", count: "11"},
        {image: "/images/icons/implementing.png", text: "KPI monitoring and reporting.", count: "12"},
        {image: "/images/icons/administration.png", text: "Debtor Administration.", count: "13"},
    ];
    return (
        <div className="bg-lk_white_backround">
            <CustomHeader heading="Warehousing & Distribution" subHeading="Services" breadcrumbs={[
                {name: "Home", href: '/', count: 1},
                {name: "Services", href: '/services', count: 2},
                {name: "Warheousing & Distribution", href: '/services/warehousing-and-distribution', count: 3},
            ]}/>

            <p className="text-lk_grey_one text-2xl sm:text-3xl lg:text-5xl mx-auto my-12 text-center px-4 max-w-xs sm:max-w-2xl lg:max-w-7xl">Lakato currently provides a Warehousing and Distribution service to numerous Animal Health Pharmaceutical Suppliers.</p>
            <p className="mx-auto text-center mb-12 text-lg px-4 max-w-xs sm:max-w-2xl lg:max-w-7xl">A facility, registered with all the relevant local authorities, is used as a Storage and Distribution Centre. An extensive Quality Management System is in place and the business is run in strict compliance to Good Warehousing and Distribution practices. Extensive Quality Agreements are in place between Lakato and its principles.</p>

            <ServicesImageCard
                image="/images/services/lakato_warehousing_and_distribution_header.jpg" imageAlt="Lakato Warehouse"
                textOne={"This facility is subject to regular internal and external quality audits. Through partnering with its current suppliers, Lakato has a wealth of knowledge of the entire South African Animal Health customer base. In addition to this, it has extensive experience in the storage and delivery of all forms of cold chain products (including: standard 2-8 degrees, liquid Nitrogen and -70-degree products) as well as the distribution of all Animal Health products not only within South Africa but into Africa too."}
                textTwo={"Lakato’s Warehousing and Distribution offering is tailor made to meet the individual objectives and needs of each supplier."}
            />

            <div className="mt-24 pb-16 lg:px-20 bg-lk_grey_background_dark_d9">
                <div className="max-w-7xl mx-auto">
                    <p className="text-lg sm:text-2xl lg:text-4xl text-center py-12">The types of services which are rendered by Lakato include:</p>
                    <Fade left>
                        <div className="relative flex justify-center">
                            <img
                                src="/images/icons/house.png"
                                alt="Lakato Warehouse"
                                className="absolute top-0 bg-white rounded-full p-4"
                            />
                            <div className="bg-white border_radius_10 mx-2 mt-16 mb-4 px-10 pt-10 pb-12 min-h-72 shadow-card_border text-center top-10">
                                <p className="text-4xl text-lk_grey_one pt-4">01</p>
                                <p className="text-xl text-lk_grey_two pt-2">The provision of a Licenced facility which, in compliance with GWP/GDP guidelines, Warehouses and Distributes Animal Health Pharmaceuticals and/or Medical devices. QA Agreements are entered into between both parties and SLA’s are in place with all service providers. </p>
                            </div>
                        </div>
                    </Fade>
                    <div className="flex flex-wrap justify-between">
                        {services.map((service) => (
                            <IconCard
                                key={service.text}
                                image={service.image}
                                count={service.count}
                                text={service.text}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
