import React from "react";
import { Link } from "react-router-dom";
import Fade  from 'react-reveal/Fade';

export default function HomeCard({ image, heading, text, link }) {
    const backgroundImage = {
        background: `url('${image}') no-repeat center center`,
        backgroundSize: "cover",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px"
    }
    return (
        <Fade up>
            <div className="border_radius_10 lg:mx-auto shadow-card_border mx-4 sm:w-56 lg:w-72 xl:w-auto sm:mx-2 lg:mx-4 my-4">
                {/* Card Image */}
                <div 
                    className="h-48 p-5 w-full relative"
                    style={backgroundImage}>
                    <h1 className="text-white text-4xl sm:text-2xl lg:text-4xl absolute bottom-5 w-60">{heading}</h1>
                </div>
                {/* Card Text */}
                <div className="px-5 pt-6 pb-10">
                    <p className="max-w-xs pr-5 text-lg mb-12">
                        {text}
                    </p>
                    <Link
                        className={"bg-lk_dark_grey_button text-white hover:bg-lk_dark_grey_button_hover uppercase border_radius_10 py-3 block text-center w-full text-base font-semibold"}
                        as="a"
                        to={link}
                    >
                        Find out more
                    </Link>
                </div>
            </div>
        </Fade>
    );
}
