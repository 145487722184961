import React from "react";
import { useSelector } from "react-redux";

import ModalNotification from "../../../features/modals/ModalNotification";
import ModalLogin from "../../../features/modals/ModalLogin";
import { ConfirmRepeatOrder } from "../../../features/modals/ConfirmRepeatOrder";
import EnquirySent from "../../../features/modals/EnquirySent";
import Alternative from "../../../features/modals/Alternative";
import OrderFailed from "../../../features/modals/OrderFailed";
import OnHoldModal from "../../../features/modals/OnHoldModal";
import PromotionFree from "../../../features/modals/PromotionFree";
import PromotionDiscount from "../../../features/modals/PromotionDiscount";
import TemplateName from "../../../features/modals/TemplateName";
import StockIssue from "../../../features/modals/StockIssue";
import ShowPromotion from "../../../features/modals/ShowPromotion";
import ScriptHelp from "../../../features/modals/ScriptHelp";
import OrderBackorder from "../../../features/modals/OrderBackorder";
import PriceList from "../../../features/modals/PriceList";

export default function ModalManager() {
    const modalLookup = {
        ModalNotification,
        ModalLogin,
        ConfirmRepeatOrder,
        EnquirySent,
        Alternative,
        OrderFailed,
        OnHoldModal,
        PromotionFree,
        PromotionDiscount,
        TemplateName,
        StockIssue,
        ShowPromotion,
        ScriptHelp,
        OrderBackorder,
        PriceList,
    };

    const currentModal = useSelector((state) => state.modals);
    let renderedModal;

    if (currentModal) {
        const { modalType, modalProps } = currentModal;
        const ModalComponent = modalLookup[modalType];
        renderedModal = <ModalComponent {...modalProps} />;
    }

    return <span>{renderedModal}</span>;
}
