import React from "react";
import Fade  from 'react-reveal/Fade';

export default function ContactCard({ heading, address, tel, fax }) {
    return (
        <Fade left>
            <div className="border_radius_10 mx-4 my-4 w-72 md:w-80 lg:w-96 xl:w-contact_card shadow-card_border bg-white">
                <div className="border-b border-gray-200 h-30">
                    <p className="text-lg sm:text-2xl lg:text-4xl lk_grey_two p-10 uppercase">{heading}</p>
                </div>
                <div className="py-5 sm:py-10">
                    <div className="sm:flex px-10 pb-5">
                        <p className="text-md text-lk_grey_one sm:pr-5 w-20">Address:</p>
                        <p className="text-md sm:pl-10">{address}</p>
                    </div>
                    <div className="sm:flex px-10 pb-5">
                        <p className="text-md text-lk_grey_one sm:pr-5 w-20">Tel:</p>
                        <p className="text-md sm:pl-10">{tel}</p>
                    </div>
                    <div className="sm:flex px-10 pb-5">
                        <p className="text-md text-lk_grey_one sm:pr-5 w-20">Fax:</p>
                        <p className="text-md sm:pl-10">{fax}</p>
                    </div>
                </div>
            </div>
        </Fade>
    );
}
