import React, { Fragment, useEffect, useState } from 'react'
// import { useHistory } from "react-router-dom";
import { closeModal } from "../../app/common/modals/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCheckCircle, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Dialog, Transition } from '@headlessui/react';
import { faExclamation, faShoppingCart, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import CustomButton from '../../app/common/form/CustomButton';
import axios from 'axios';
import CustomText from '../../app/common/form/CustomText';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from "react-toastify";
import ReactTooltip from "react-tooltip";

export default function PromotionFree(props) {
    // let history = useHistory();
    const dispatch = useDispatch();
    const [open] = useState(true);
    const { currentUser } = useSelector((state) => state.auth);
    const [cartTotal, setCartTotal] = useState([])
    const [products, setProducts] = useState([])
    const [total, setTotal] = useState("0");
    const [maxTotal, setMaxTotal] = useState("");
    const [buyRequired, setBuyRequired] = useState(props.Promotion.col15[0].rule_qty);
    const [purchaseTotal, setPurchaseTotal] = useState(0);
    const [freeTotal, setFreeTotal] = useState(0);

    // console.log(props)

    let promotion = props.Promotion;

    useEffect(() => {
        setCartTotal(parseFloat(window.sessionStorage.getItem("itemsInBasket")));
        getProductsSpecial();
    }, []);
  
    async function getProductsSpecial(){
        let specialList = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}special-products`,
            headers: { 
                Authorization: `Bearer ${currentUser.token}`
            },
            data:{
                productsReq: promotion.col11,
                productsFree: promotion.col12,
                products: [promotion.col11, promotion.col12].flat(),
                customer_code: currentUser.isCustomer === 1 && currentUser.customer_code !== null ? currentUser.customer_code : currentUser.customer_code === null || currentUser.customer_code.split(",").length > 1 ? "None" : currentUser.customer_code,
                branch: currentUser.branch,
                userId: currentUser.id,
            }
        });

        let reqItems = specialList.data[0];
        let cartItems = specialList.data[1];
        let prodDataSubCode = specialList.data[2];

        let allCodes = [];

        prodDataSubCode.forEach(function (subCode) {
            allCodes.push(subCode.stock_code)
        });

        console.log(promotion)

        let allItems = [];
        

        await Object.keys(reqItems).forEach(async function (index) {
            let total = 1;
            let inCart = "no";
            await Object.keys(cartItems).forEach(function (key) {
                if(reqItems[index].StockCode === cartItems[key].stock_code){
                    total = cartItems[key].qty
                    inCart = "yes";
                }
            })

            reqItems[index].display = "";

            if(prodDataSubCode.length > 0 && allCodes.includes(reqItems[index].StockCode)){
                console.log('sd')
                prodDataSubCode.forEach(function (subCode) {
                    if (subCode.stock_code == reqItems[index].StockCode) {
                        reqItems[index].display = subCode.custom_code
                    }
                });
            }

            reqItems[index].qty = total;
            reqItems[index].inCart = inCart;
            allItems.push(reqItems[index])
        })

        setProducts(allItems);
    }

    // async function claimDeal(){
    //     //
    // }

    async function setTotals(totalAmount, itemId) {

        itemId = itemId.trim();

        await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}update-qty-in-cart`,
          headers: { Authorization: `Bearer ${currentUser.token}` },
          data: {
            StockCode: itemId,
            id: currentUser.id,
            qty: totalAmount,
            customer_code: currentUser.customer_code,
          },
        });

        await getProductsSpecial();
        
    }

    async function addItemToCart(itemId, qty, qtyNo){

        let newQty = parseFloat(document.getElementById(qtyNo).value);
        itemId = itemId.trim();

        let cartItem = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}add-items-to-cart`,
          headers: { Authorization: `Bearer ${currentUser.token}` },
          data: {
            StockCode: itemId,
            id: currentUser.id,
            qty: newQty,
            auto: 0,
            customer_code: currentUser.customer_code,
          },
        });

        if(cartItem.data == 1){
            let CurrentCartAmount = window.sessionStorage.getItem("itemsInBasket")
            let NewAmount = parseFloat(CurrentCartAmount) + 1
            window.sessionStorage.setItem("itemsInBasket", NewAmount);
        }

        if(newQty >= parseFloat(buyRequired)){

            let totalFree = Math.floor(newQty / parseFloat(buyRequired))

            let cartItem2 = await axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}add-items-to-cart`,
                headers: { Authorization: `Bearer ${currentUser.token}` },
                data: {
                    StockCode: promotion.col12[0].trim(),
                    id: currentUser.id,
                    qty: Math.floor(newQty / parseFloat(buyRequired)),
                    auto: 0,
                    customer_code: currentUser.customer_code,
                },
            });

            if(cartItem2.data == 1){
                let CurrentCartAmount2 = window.sessionStorage.getItem("itemsInBasket")
                let NewAmount2 = parseFloat(CurrentCartAmount2) + 1
                window.sessionStorage.setItem("itemsInBasket", NewAmount2);
            }
        }

        toast.success("Item Added to Cart", {position: "bottom-right", autoClose: 5000, closeOnClick: true,});

        await getProductsSpecial();

        
    }

    return (
        <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 transition ease-in duration-300"
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                </div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                    <ToastContainer />
                </span>
                
                <div 
                    className="
                        inline-block align-middle bg-white rounded-lg px-4 py-5 pt-5 pb-4 
                        overflow-hidden shadow-xl transform transition-al
                    "
                >
                    <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                        <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                            onClick={() => dispatch(closeModal())}
                        >
                            <span className="sr-only">Close</span>
                            <FontAwesomeIcon className="text-lk_red text-2xl hover:text-lk_red_hover" aria-hidden="true" icon={faTimesCircle}/>
                        </button>
                    </div>
                    <div>
                        <div className="mt-3 text-center sm:mt-16">
                            <h1 className="text-lg sm:text-2xl lg:text-4xl uppercase">
                                {promotion.col1}
                            </h1>
                            <p>{promotion.col17}</p>
                            {promotion.col16 !== "" && promotion.col16 !== undefined && (
                                <><br/><img src={process.env.REACT_APP_URL+"fliers/"+promotion.col16} style={{maxHeight:"500px", margin: "auto"}} /></>
                            )}
                            <div className="mt-2 sm:px-32 mt-12 mb-5">
                                <div className="mt-2 grid grid-cols-1 gap-4">
                                    <div>
                                        <h2 className="text-2xl">Buy requirement</h2>
                                        <br/>
                                        <table className="border-collapse border border-slate-400">
                                            <thead>
                                                <tr className="bg-gray-100 flex flex-col flex-no wrap md:table-row w-full">
                                                    <th className="lg:border border-slate-300">Stock Code</th>
                                                    <th className="lg:border border-slate-300">Description</th>
                                                    <th className="lg:border border-slate-300">Price Per Unit</th>
                                                    <th className="lg:border border-slate-300">Stock Status</th>
                                                    <th className="lg:border border-slate-300">Qty</th>
                                                    <th className="lg:border border-slate-300"></th>
                                                </tr>
                                            </thead>
                                            {products.map((product) => ( 
                                                <>
                                                    {(promotion.col11).includes(product.StockCode) ? 
                                                        <tr className="bg-white rounded mt-5 flex flex-col flex-no wrap md:table-row">
                                                            {promotion.col7 == 1 || promotion.col13 == 2 ? 
                                                                <td className="lg:border md:border-r border-slate-300"> 
                                                                    <input type="checkbox" onClick={(value) => {
                                                                        console.log(value.target.checked); 
                                                                        var total = purchaseTotal; 
                                                                        if(value.target.checked == true){
                                                                            var total = purchaseTotal + 1; 
                                                                        }else{
                                                                            var total = purchaseTotal - 1; 
                                                                        }
                                                                        
                                                                        setPurchaseTotal(total)
                                                                        }}/> 
                                                                </td> 
                                                            : ""}
                                                            <td className="lg:border md:border-r border-slate-300 lg:px-5 lg:w-56 lg:h-20 md:px-5 md:w-56 md:h-20 sm:text-2xl lg:text-base md:text-base"> 
                                                                {/* {product.StockCode}  */}
                                                                {product.display !== "" ? product.display : product.StockCode}
                                                            </td>
                                                            <td className="lg:border md:border-r border-slate-300"> 
                                                                {product.Description} {product.LongDesc} 
                                                            </td>
                                                            <td className="lg:border md:border-r border-slate-300 px-2"> 
                                                                    R {parseFloat(product.SellingPrice).toFixed(2)}
                                                                </td>
                                                                <td className="lg:border md:border-r border-slate-300 px-2 sm:text-xl lg:text-base md:text-base"> 
                                                                    {/* {product.InvWarehouse} */}

                                                                    {product.InvWarehouse === "Out of Stock" ?
                                                                        <p className="text-red-500">{product.InvWarehouse}</p> 
                                                                    : <p>{product.InvWarehouse}</p> }
                                                                </td>
                                                                <td className="lg:border md:border-r border-slate-300 px-2"> 
                                                                    <div>
                                                                        {product.InvWarehouse === "Out of Stock" ?
                                                                        ""
                                                                        : 
                                                                        <input type="number" id={"number_"+product.StockCode} min={product.inCart == "yes" ? "1" : "0"} defaultValue = {product.qty} className="shadow-sm text-sm border border_radius_5 w-20" onChange={(values) => {let mintot = product.inCart == "yes" ? 1 : 0; parseFloat(values.target.value) < mintot ? setTotals(mintot, product.StockCode) : setTotals(values.target.value, product.StockCode); if(values.target.value == 0){values.target.value = mintot}else{values.target.value = Math.abs(values.target.value)}}}/>}
                                                                    </div>
                                                                </td>
                                                                <td className="flex">
                                                                    {product.InvWarehouse  === "Out of Stock" ? 
                                                                        <>
                                                                            
                                                                        </> 
                                                                    : 
                                                                        <>
                                                                            <button
                                                                                className="px-4 py-2 lg:mr-5 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto flex lg:mt-3 lg:ml-2 md:mt-3 md:ml-2"
                                                                                onClick={() =>
                                                                                    addItemToCart(
                                                                                        product.StockCode,
                                                                                        product.qty,
                                                                                        "number_"+product.StockCode
                                                                                    )
                                                                                }
                                                                            >
                                                                                {/* Add to cart */}
                                                                                {/* + <FontAwesomeIcon icon={faShoppingCart}/> */}
                                                                                <span className="pr-2">+</span> <FontAwesomeIcon icon={faShoppingCart} className="mt-1"/>
                                                                            </button>

                                                                            {product.inCart == "yes" ? (
                                                                                <span className="mt-6 bg-red-500 h-7 px-2 py-1 rounded-full text-white mr-1 min-w-min_red_btn flex justify-center" data-tip={product.qty +" of this item is in your cart"}>
                                                                                {product.qty}
                                                                                <ReactTooltip />
                                                                                </span>
                                                                            ) : (
                                                                                <div className="mt-6 h-6 px-2 py-1 rounded-full text-white"></div>
                                                                            )}
                                                                            
                                                                        </>
                                                                    }
                                                                </td>
                                                        </tr> 
                                                    : ""}
                                                        
                                                </>
                                            ))}
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2 sm:px-32 mt-12 mb-5">
                                <div className="mt-2 grid grid-cols-1 gap-4">
                                    <div>
                                    <h2 className="text-2xl">Free Product</h2>
                                    <br/>
                                    <table className="border-collapse border border-slate-400 w-full">
                                            <tr className="bg-gray-100 flex flex-col flex-no wrap md:table-row w-full">
                                                {promotion.col7 == 1 || promotion.col13 == 2 ? 
                                                    <th className="border border-slate-300">Select</th>
                                                :" " }
                                                <th className="lg:border border-slate-300">Stock Code</th>
                                                <th className="lg:border border-slate-300">Description</th>
                                                <th className="lg:border border-slate-300">Price Per Unit</th>
                                                <th className="lg:border border-slate-300">Stock Status</th>
                                                {/* <th className="border border-slate-300">Qty</th> */}
                                                {/* <th className="border border-slate-300"></th> */}
                                            </tr>
                                            {products.map((product) => ( 
                                                <>
                                                {/* {console.log(promotion)} */}
                                                    {(promotion.col12).includes(product.StockCode) ? 
                                                        <tr className="bg-white rounded mt-5 flex flex-col flex-no wrap md:table-row">
                                                            {promotion.col7 == 1 || promotion.col13 == 2 ? 
                                                                <td className="lg:border md:border-r border-slate-300"> 
                                                                    <input type="checkbox" 
                                                                        onClick={(value) => {
                                                                            console.log(value.target.checked); 
                                                                            var total = freeTotal; 
                                                                            if(value.target.checked == true){
                                                                                var total = freeTotal + 1; 
                                                                            }else{
                                                                                var total = freeTotal - 1; 
                                                                            }
                                                                            
                                                                            setFreeTotal(total)
                                                                        }}/>  
                                                                    
                                                                </td> 
                                                            : ""}
                                                            <td className="lg:border md:border-r border-slate-300 lg:px-5 lg:w-56 lg:h-20 md:px-5 md:w-56 md:h-20 sm:text-2xl lg:text-base md:text-base"> 
                                                                {/* {product.StockCode}  */}
                                                                {product.display !== "" ? product.display : product.StockCode}
                                                            </td>
                                                            <td className="lg:border md:border-r border-slate-300"> 
                                                                {product.Description} {product.LongDesc} 
                                                            </td>
                                                            <td className="lg:border md:border-r border-slate-300 px-2"> 
                                                                    R {parseFloat(product.SellingPrice).toFixed(2)}
                                                                </td>
                                                                <td className="lg:border md:border-r border-slate-300 px-2 lg:text-base md:text-base sm:text-xl"> 
                                                                    {product.InvWarehouse}
                                                                </td>
                                                                {/* <td className="border border-slate-300 px-2"> 
                                                                    <div>
                                                                        {product.InvWarehouse === "Out of Stock" ?
                                                                        ""
                                                                        : 
                                                                        <input type="number" min='0' defaultValue = {product.qty} className="shadow-sm text-sm border border_radius_5 w-20" onChange={(values) => {parseFloat(values.target.value) < 0 ? setTotals(0, product.StockCode) : setTotals(values.target.value, product.StockCode); values.target.value = Math.abs(values.target.value)}}/>}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {product.InvWarehouse  === "Out of Stock" ? 
                                                                        <>
                                                                            
                                                                        </> 
                                                                    : 
                                                                        <>
                                                                            <button
                                                                                className="px-4 py-2 mr-5 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto"
                                                                                onClick={() =>
                                                                                    addItemToCart(
                                                                                        product.StockCode,
                                                                                        1
                                                                                    )
                                                                                }
                                                                            >
                                                                                Add to cart
                                                                            </button>
                                                                        </>
                                                                    }
                                                                </td> */}
                                                        </tr> 
                                                    : ""}
                                                        
                                                </>
                                            ))}
                                        </table>
                                    </div>
                                </div>

                                <br/><br/>
                                {/* <label>Number of Promotions: </label>
                                <input type="number" min='0' className="focus:ring-gray-500 focus:border-gray-500 border-gray-300" onChange={(value) => setTotal(value.target.value)}/> */}
                                

                                <br/><br/>
                                <div >
                                    {/* <div>
                                        <CustomButton
                                            type="button"
                                            text="Claim Promotion"
                                            className="lkbutton bg-red-600 hover:bg-red-800 w-60 border_radius_10 "
                                            onClick={() => claimDeal()}
                                        />
                                    </div> */}
                                    <div>
                                        <CustomButton
                                            type="button"
                                            text="Close"
                                            className="lkbutton bg-gray-400 hover:bg-gray-600 w-60 border_radius_10 "
                                            onClick={() => dispatch(closeModal())}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}