import React from "react";

export default function WholesaleImageCard({image, imageAlt, text, imageAlign}) {
    return (
        <div className="px-4 lg:px-12 relative max-w-7xl mx-auto flex flex-wrap lg:flex-nowrap">
            {imageAlign === "1" && (
                <>
                    <img
                        src={image}
                        alt={imageAlt}
                        className="xl:my-4 my-0 shadow-card_border border_radius_10 bg-white z-10 md:w-contact_card xl:w-about_us_card xl:h-about_us_card mx-auto"
                    />
                    <p className="px-4 py-4 my-4 lg:my-12 xl:my-0 lg:px-12 xl:-ml-12 mx-auto xl:mt-44 xl:mr-8 xl:pt-8 xl:pb-24 xl:pl-24 xl:pr-12 z-0 md:w-contact_card xl:w-about_us_card align-center whitespace-normal bg-white text-lk_grey_two text-sm sm:text-md xl:text-xl shadow-card_border border_radius_10 ">
                        {text}
                    </p>
                </>
            )}
            {imageAlign === "2" && (
                <>
                    <p className="order-last xl:order-last mx-auto px-4 py-4 lg:my-12 lg:px-12 my-4 xl:-mt-6 xl:-mr-12 xl:pt-16 xl:pr-24 xl:mb-24 xl:pl-8 z-0 md:w-contact_card xl:w-about_us_card align-center whitespace-normal bg-white text-lk_grey_two text-sm sm:text-md xl:text-xl shadow-card_border border_radius_10 ">
                        {text}
                    </p>
                    <img
                        src={image}
                        alt={imageAlt}
                        className="order-first xl:order-last xl:-mt-2 lg:mt-4 xl:mt-0 shadow-card_border border_radius_10 bg-white z-10 md:w-contact_card xl:w-about_us_card xl:h-about_us_card mx-auto"
                    />
                </>
            )}
            {imageAlign === "3" && (
                <>
                    <img
                        src={image}
                        alt={imageAlt}
                        className="lg:mt-4 xl:-mt-36 xl:ml-12 xl:h-about_us_card mx-auto shadow-card_border border_radius_10 bg-white z-10 "
                    />
                    <p className="px-4 py-4 my-2 lg:my-24 lg:p-16 order-2 mx-auto xl:-ml-12 xl:mt-8 xl:mr-36 xl:pt-8 xl:mb-16 xl:pl-24 xl:pr-24 z-0 md:w-contact_card xl:w-about_us_card align-center whitespace-normal bg-white text-lk_grey_two text-sm sm:text-md xl:text-xl shadow-card_border border_radius_10 ">
                        {text}
                    </p>
                </>
            )}
        </div>
    )
}