import React from "react";
import { Link } from "react-router-dom";
import CatalogueCTA from "../../app/common/elements/CatalogueCTA";
import CustomHeader from "../../app/common/elements/CustomHeader";
import HomeCard from "../../app/common/elements/HomeCard";

export default function HomePage() {
    const testimonials = [
        {
            image: "/images/testimonialPlaceholderOne.jpg",
            text: '“ Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”',
            company: "Name Surname, Company",
        },
        {
            image: "/images/testimonialPlaceholderTwo.jpg",
            text: '“ Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”',
            company: "Name Surname, Company",
        },
        {
            image: "/images/testimonialPlaceholderThree.jpg",
            text: '“ Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”',
            company: "Name Surname, Company",
        },
        {
            image: "/images/testimonialPlaceholderOne.jpg",
            text: '“ Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”',
            company: "Name Surname, Company",
        },
        {
            image: "/images/testimonialPlaceholderTwo.jpg",
            text: '“ Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”',
            company: "Name Surname, Company",
        },
        {
            image: "/images/testimonialPlaceholderThree.jpg",
            text: '“ Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”',
            company: "Name Surname, Company",
        },
    ]

    return (
        <>
            <CustomHeader home image="/images/home_banner_background.jpg" />

            <div className="max-w-xs px-4 sm:max-w-2xl lg:max-w-7xl mx-auto flex flex-col align-center mb-24 load_in">
                <p className="text-center pt-9 pb-12 m-auto max-w-4xl text-lg">
                    Lakato supplies a comprehensive range of Animal Health products via its national branch network to Veterinary practices, Veterinarian owned retail outlets and professional pet retail businesses throughout South Africa and Southern Africa.
                </p>
                <Link
                    className={"bg-lk_red text-white hover:bg-lk_red_hover uppercase border_radius_10 py-3 px-6 md:px-24 m-auto text-base font-semibold"}
                    as="a"
                    to="/about-us"
                >
                    Find out more
                </Link>
            </div>

            {/* Cards */}
            <div className="sm:flex sm:flex-inline flex-wrap justify-center max-w-7xl mx-auto">
                <HomeCard
                    image="/images/products_link.jpg"
                    heading="Our Products"
                    text="Lakato provides a One Stop Shop to customers in the Animal Health Industry while carrying the broadest range of products possible."
                    link="/products"
                />
                <HomeCard
                    image="/images/warehousing_and_distribution_link.jpg"
                    heading="Warehousing & Distribution"
                    text="Lakato currently provides a Warehousing and Distribution service to numerous Animal Health Pharmaceutical Suppliers."
                    link="/services/warehousing-and-distribution"
                />
                <HomeCard
                    image="/images/online_ordering_placeholder.jpg"
                    heading="Online Ordering"
                    headingWith="200px"
                    text="Lakato provides delivery and a completely online booking system for our customers to receive our wide array of products."
                    link="/online-ordering"
                />
            </div>

            <div className="my-28" />

            <CatalogueCTA />

            {/* <div className="max-w-xs pl-2 sm:pl-0 sm:max-w-2xl lg:max-w-4xl xl:max-w-7xl mx-auto pb-20">
                <GreyHeading text="Testimonials" />
                <CustomSlider items={testimonials} />
            </div> */}
        </>
    );
}
