import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import AdminSearchForm from "../../../forms/AdminSearchForm";
import AdminNav from "../../../nav/AdminNav";
import { useTable, usePagination } from 'react-table'
import Select from 'react-select'
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { faExclamationCircle, faHeart as solidHeart, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
// import { useDispatch } from "react-redux";
// import { openModal } from "../../../../app/common/modals/modalReducer";
import { useHistory } from "react-router";
import { openModal } from "../../../../app/common/modals/modalReducer"
import ReactTooltip from "react-tooltip";
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from "react-toastify";

export default function SpecificOrder(props) {

    let history = useHistory();
    let dispatch = useDispatch();

    let orderId = props.match.params.orderId;
    // const dispatch = useDispatch();

    const { currentUser } = useSelector((state) => state.auth);
    const [cartTotal, setCartTotal] = useState([])
    const [orderDetails, setOrderDetails] = useState([])
    const [paymentDetails, setPaymentDetails] = useState([])
    const [orderItems, setOrderItems] = useState([])
    const [orderTrack, setOrderTrack] = useState([])
    const [comments, setComments] = useState([]);

    const allowedSups = currentUser.supplier_stock != null ? currentUser.supplier_stock.split(",").map(function (item) { return item.trim(); }) : currentUser.supplier_stock;

    useEffect(() => {
        setCartTotal(parseFloat(window.sessionStorage.getItem("itemsInBasket")));
        getOrder();
    }, [])



    async function getOrder() {
        let order = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}this-orders`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                userId: currentUser.id,
                orderId: orderId,
                customer_code: currentUser.isCustomer === 1 && currentUser.customer_code !== null ? currentUser.customer_code : currentUser.customer_code === null || currentUser.customer_code.split(",").length > 1 ? "None" : currentUser.customer_code,
            },
        });

        let orderDetails = order.data;
        setOrderDetails(orderDetails[0]);
        setPaymentDetails(orderDetails[1]);
        setOrderTrack(orderDetails[2]);
        setComments(orderDetails[3]);

        let favouritesResults = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}favourites`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                userId: currentUser.id
            },
        });

        let cartItems = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}get-items-in-cart`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                id: currentUser.id,
                priceCode: currentUser.customer_code,
                branch: currentUser.branch,
            },
        });

        let cartItemData = cartItems.data[1];

        let favData = favouritesResults.data[0];
        let prodDataSubCode = favouritesResults.data[1];
        let favIds = [];

        await Object.keys(favData).forEach(function (key) {
            favIds.push(favData[key].favourite_product_id)
        })

        let allCodes = [];

        prodDataSubCode.forEach(function (subCode) {
            allCodes.push(subCode.stock_code)
        });

        let ordeItems = orderDetails[0];
        let allproducts = [];

        await Object.keys(ordeItems).forEach(async function (key) {

            let curfav = false;

            if (favIds.includes((ordeItems[key].MStockCode).trim())) {
                curfav = true;
            }

            let display = "";

            if (prodDataSubCode.length > 0 && allCodes.includes(ordeItems[key].MStockCode)) {
                prodDataSubCode.forEach(function (subCode) {
                    if (subCode.stock_code == ordeItems[key].MStockCode) {
                        display = subCode.custom_code
                    }
                });
            }

            let inCart = "no";

            await Object.keys(cartItemData).forEach(function (index) {
                if (
                    cartItemData[index].stock_code === ordeItems[key].MStockCode

                ) {
                    inCart = "yes";
                }
            });

            // MPrice
            // col5: "R " + parseFloat(ordeItems[key].MUnitCost).toFixed(2), 

            allproducts.push({
                col0: ordeItems[key].MStockCode,
                col1: '',
                col2: ordeItems[key].MStockCode,
                col3: ordeItems[key].MStockDes,
                col4: ordeItems[key].InvWarehouse,
                col5: "R " + parseFloat(ordeItems[key].MPrice).toFixed(2),
                col6: ordeItems[key].MOrderQty,
                col7: curfav,
                col9: ordeItems[key].altStock,
                col10: ordeItems[key].AlternateUom,
                col11: inCart,
                col12: display,
                col13: ordeItems[key].img,
                col23: ordeItems[key].UserField1,
                col24: ordeItems[key].AlternateKey2,
                col25: ordeItems[key].InvwarehouseQty,
                col26: ordeItems[key].MBackOrderQty,
                col27: ordeItems[key].Supplier,
            })
        })

        setOrderItems(allproducts)
    }

    async function addItemToCart(itemId, qty) {

        itemId = itemId.trim();

        let cartItem = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}add-items-to-cart`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                StockCode: itemId,
                id: currentUser.id,
                qty: qty,
                auto: 0,
                customer_code: currentUser.customer_code,
            },
        });

        if (cartItem.data == 1) {
            let CurrentCartAmount = window.sessionStorage.getItem("itemsInBasket")
            let NewAmount = parseFloat(CurrentCartAmount) + 1
            window.sessionStorage.setItem("itemsInBasket", NewAmount);
        }

        toast.success("Item Added to Cart", { position: "bottom-right", autoClose: 5000, closeOnClick: true, });

        await getOrder();

        //   itemId = itemId.trim();
        //   let prevItemClicked = JSON.parse(
        //       window.sessionStorage.getItem("itemsClicked")
        //   );
        //   if (prevItemClicked !== null) {
        //       window.sessionStorage.setItem(
        //           "itemsClicked",
        //           JSON.stringify([...prevItemClicked, itemId])
        //       );
        //   } else {
        //       window.sessionStorage.setItem(
        //           "itemsClicked",
        //           JSON.stringify(itemId)
        //       );
        //   }

        //   let prevItemIds = JSON.parse(
        //       window.sessionStorage.getItem("itemsIdsInBasket")
        //   );

        //   if (!prevItemIds.includes(itemId)) {
        //       window.sessionStorage.setItem(
        //           "itemsIdsInBasket",
        //           JSON.stringify([...prevItemIds, itemId])
        //       );

        //       let CurrentCartAmount =
        //           window.sessionStorage.getItem("itemsInBasket");
        //       let NewAmount = parseFloat(CurrentCartAmount) + 1;
        //       window.sessionStorage.setItem("itemsInBasket", NewAmount);
        //       setCartTotal(NewAmount);

        //   } else {
        //       let totalItemsClicked = JSON.parse(
        //           window.sessionStorage.getItem("itemsClicked")
        //       );
        //       let thisItem = 0;

        //       await Object.keys(totalItemsClicked).forEach(function (key) {
        //           if (totalItemsClicked[key] === itemId) {
        //               thisItem += 1;
        //           }
        //       });

        //       let prevItemTotals = JSON.parse(
        //           window.sessionStorage.getItem("totalItemsInBasket")
        //       );
        //       if (prevItemTotals !== null) {
        //           window.sessionStorage.setItem(
        //               "totalItemsInBasket",
        //               JSON.stringify([...prevItemTotals, [thisItem, itemId]])
        //           );
        //       } else {
        //           window.sessionStorage.setItem(
        //               "totalItemsInBasket",
        //               JSON.stringify([[thisItem, itemId]])
        //           );
        //       }
        //   }
    }

    async function addFavourite(rowId) {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}add-new-favourite`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                rowId: rowId,
                userId: currentUser.id
            },
        });

        getOrder();
    }

    async function removeFavourite(rowId) {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}remove-favourite`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                rowId: rowId,
                userId: currentUser.id
            },
        });

        getOrder();
    }

    async function setTotals(totalAmount, itemId) {
        itemId = itemId.trim();

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}update-qty-in-cart`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                StockCode: itemId,
                id: currentUser.id,
                qty: totalAmount,
                customer_code: currentUser.customer_code,
            },
        });

        await getOrder();
        //   let prevItemTotals = JSON.parse(window.sessionStorage.getItem("totalItemsInBasket"));
        //   if(prevItemTotals !== null){
        //     window.sessionStorage.setItem("totalItemsInBasket", JSON.stringify([...prevItemTotals, [totalAmount, itmeId]]));
        //   }else{
        //     window.sessionStorage.setItem("totalItemsInBasket", JSON.stringify([[totalAmount, itmeId]]));
        //   }    
    }

    const data = React.useMemo(() => orderItems, [orderItems])

    const columns = React.useMemo(
        () => [
            {
                Header: 'Image',
                accessor: 'col1',
                Cell: tableProps => (
                    <img
                        src={tableProps.row.original.col13 !== "" ? process.env.REACT_APP_URL + 'media/' + tableProps.row.original.col13 : process.env.REACT_APP_URL + "/images/placeholder-image.png"}
                        width={150}
                        alt='Product'
                        className="mx-auto px-5 py-5"
                    />
                )
            },
            {
                Header: 'Code',
                accessor: 'col2',
                Cell: (rowInfo) => {
                    return (
                        <>
                            {rowInfo.row.original.col12 !== "" ? rowInfo.row.original.col12 : rowInfo.row.original.col2}
                        </>
                    )
                }
            },
            {
                Header: 'Description',
                accessor: 'col3',
            },
            // {
            //     Header: 'Pet Brands',
            //     accessor: 'col23',
            //     Cell: (rowInfo) => {
            //         return (
            //             <>
            //                 {rowInfo.row.original.col23 !== ""
            //                     ? rowInfo.row.original.col23 + " - " + rowInfo.row.original.col24
            //                     : rowInfo.row.original.col23 + " - " + rowInfo.row.original.col24}
            //             </>
            //         );
            //     },
            // },
            {
                Header: 'Stock Status',
                accessor: 'col4',
                Cell: (rowInfo) => {
                    return (
                        <>
                            {rowInfo.row.original.col4 === "Out of Stock" ?
                                <p className="text-red-500">{rowInfo.row.original.col4} {currentUser.see_stock === 1 && currentUser.supplier_stock !== null && allowedSups.includes(rowInfo.row.original.col27) ? <>{"( "}{rowInfo.row.original.col25}{" )"}</> : ""}</p>
                                : <p>{rowInfo.row.original.col4} {currentUser.see_stock === 1 && currentUser.supplier_stock !== null && allowedSups.includes(rowInfo.row.original.col27) ? <>{"( "}{rowInfo.row.original.col25}{" )"}</> : ""}</p>}

                        </>
                    );
                },
            },
            {
                Header: 'Price Excl. VAT',
                accessor: 'col5',
                Cell: (rowInfo) => {
                    return (
                        <>
                            <p>{rowInfo.row.original.col5}

                                {rowInfo.row.original.col10 == "SD" ?
                                    <>
                                        <span className="text-green-500 ml-2" data-tip="Settlement Discount if Applicable"><FontAwesomeIcon icon={faExclamationCircle} /></span> <ReactTooltip />

                                    </>
                                    : ""}
                            </p>



                        </>
                    );
                },
            },
            {
                Header: 'QTY',
                accessor: 'col6',
                Cell: rowInfo => {
                    return (
                        // <Select 
                        //   options={[
                        //     { value: "01", label: "01" },
                        //     { value: "02", label: "02" },
                        //     { value: "03", label: "03" }
                        //   ]}
                        //   defaultValue={{ label: rowInfo.row.original.col6, value: rowInfo.row.original.col6 }}
                        //   isSearchable={false}
                        //   onChange={(values) => setTotals(values.value, rowInfo.row.original.col0)}
                        // />


                        <div>
                            {/* {rowInfo.row.original.col4 === "Out of Stock" ?
                                ""
                                : */}
                            <input type="number" min={rowInfo.row.original.col11 == "yes" ? "1" : "0"} defaultValue={Math.floor(parseFloat(rowInfo.row.original.col6))} className="shadow-sm text-sm border border_radius_5 w-20" onChange={(values) => { let mintot = rowInfo.row.original.col11 == "yes" ? 1 : 0; parseFloat(values.target.value) < mintot ? setTotals(mintot, rowInfo.row.original.col2) : setTotals(values.target.value, rowInfo.row.original.col2); if (values.target.value == 0) { values.target.value = mintot } else { values.target.value = Math.abs(values.target.value) } }} />
                            {/* } */}
                        </div>
                    );
                },
            },
            {
                Header: 'On Back Order',
                accessor: 'col26',
                Cell: (rowInfo) => {
                    return (
                        <>
                            {parseFloat(rowInfo.row.original.col26) !== 0 ?
                                <p className="text-red-500">YES ({parseFloat(rowInfo.row.original.col26)})</p>
                                : ""}
                        </>
                    );
                },
            },
            {
                Header: "",
                accessor: "col8",
                Cell: (rowInfo) => {
                    return (
                        <div>
                            {rowInfo.row.original.col4 === "Out of Stock" ?
                                <>
                                    {parseFloat(rowInfo.row?.original?.col9?.length) > 0 ?
                                        <>
                                            <button
                                                className="px-2 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto"
                                                onClick={() => dispatch(openModal({ modalType: "Alternative", modalProps: { stockCode: rowInfo.row.original.col2 } }))}
                                            >
                                                See Alternative
                                            </button>
                                        </>
                                        : ""}
                                    {/* No Alternative Available */}

                                </>
                                :
                                ""
                            }
                        </div>
                    );
                },
            },
            {
                Header: '',
                accessor: 'col7',
                Cell: rowInfo => {
                    return (
                        <div className="flex">
                            {rowInfo.row.original.col7 === true ? (
                                <FontAwesomeIcon
                                    icon={solidHeart}
                                    className="absolute top-3 right-3 text-xl text-gray-500 cursor-pointer"
                                    onClick={() =>
                                        removeFavourite(
                                            rowInfo.row.original.col2
                                        )
                                    }
                                />
                            ) : (
                                <FontAwesomeIcon
                                    icon={faHeart}
                                    className="absolute top-3 right-3 text-xl text-gray-500 cursor-pointer"
                                    onClick={() =>
                                        addFavourite(rowInfo.row.original.col2)
                                    }
                                />
                            )}



                            {/* {JSON.parse(window.sessionStorage.getItem("itemsIdsInBasket")) !== null && JSON.parse(
                                window.sessionStorage.getItem(
                                    "itemsIdsInBasket"
                                )
                            ).includes(
                                (rowInfo.row.original.col2).trim()
                            ) ? (
                                <>
                                    <button
                                        className="px-4 py-2 mr-5 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto"
                                        onClick={() =>
                                            history.push("/view-cart")
                                        }
                                    >
                                        View Cart
                                    </button>
                                </>
                            ) : (
                                <>
                                {rowInfo.row.original.col4 === "Out of Stock" ? 
                                    <>
                                        
                                    </> 
                                : 
                                    <> */}
                            <button
                                className="px-4 py-2 mr-5 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto flex"
                                onClick={() =>
                                    addItemToCart(
                                        rowInfo.row.original.col2,
                                        rowInfo.row.original.col6
                                    )
                                }
                            >
                                {/* Add to cart */}
                                {/* + <FontAwesomeIcon icon={faShoppingCart}/> */}
                                <span className="pr-2 mt-1">+</span> <FontAwesomeIcon icon={faShoppingCart} className="mt-2" />
                            </button>

                            {rowInfo.row.original.col11 == "yes" ? (
                                <span className="mt-6 bg-red-500 h-7 px-2 py-1 rounded-full text-white mr-1 min-w-min_red_btn flex justify-center" data-tip={Math.floor(rowInfo.row.original.col6) + " of this item is in your cart"}>
                                    {Math.floor(rowInfo.row.original.col6)}
                                    <ReactTooltip />
                                </span>
                            ) : (
                                <div className="mt-6 h-6 px-2 py-1 rounded-full text-white"></div>
                            )}
                            {/* </>
                                }
                                    
                                </>
                            )} */}


                        </div>
                    );
                },
            },

        ],
        []
    )

    const tableInstance = useTable({ columns, data, manualPagination: true }, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canpreviousPage,
        prepareRow,
    } = tableInstance

    async function repeatThisOrder(orderItems) {

        await Object.keys(orderItems).forEach(function (key) {

            let itemId = (orderItems[key].col0).trim();

            let prevItemClicked = JSON.parse(window.sessionStorage.getItem("itemsClicked"));

            if (prevItemClicked !== null) {
                window.sessionStorage.setItem("itemsClicked", JSON.stringify([...prevItemClicked, itemId]));
            } else {
                window.sessionStorage.setItem("itemsClicked", JSON.stringify(itemId));
            }

            let prevItemIds = JSON.parse(window.sessionStorage.getItem("itemsIdsInBasket"));

            if (!prevItemIds.includes(itemId)) {
                window.sessionStorage.setItem("itemsIdsInBasket", JSON.stringify([...prevItemIds, itemId]));

                let CurrentCartAmount = window.sessionStorage.getItem("itemsInBasket")
                let NewAmount = parseFloat(CurrentCartAmount) + 1
                window.sessionStorage.setItem("itemsInBasket", NewAmount);
                setCartTotal(NewAmount)

            }

            let prevItemTotals = JSON.parse(window.sessionStorage.getItem("totalItemsInBasket"));
            if (prevItemTotals !== null) {
                window.sessionStorage.setItem("totalItemsInBasket", JSON.stringify([...prevItemTotals, [orderItems[key].col6, orderItems[key].col0]]));
            } else {
                window.sessionStorage.setItem("totalItemsInBasket", JSON.stringify([[orderItems[key].col6, orderItems[key].col0]]));
            }
        })

    }

    async function createTemplate(orderItems) {
        dispatch(openModal({ modalType: "TemplateName", modalProps: { orderItems: orderItems } }))
    }



    return (
        <div className="bg-gray-50">
            <AdminSearchForm
                heading="Orders"
                color="text-gray-500"
                header_color="bg-gradient-to-b from-gray-300"
                crumb_color="text-black"
                breadcrumbs={[
                    { name: "Home", href: "/", count: 1 },
                    { name: "Orders", href: "/orders", count: 2 },
                    { name: "Order", href: "/order/" + orderId, count: 3 },
                ]}
            />

            <ToastContainer />

            {/* <AdminNav cartTotal={cartTotal}/> */}
            <br /><br />

            <div className="container mx-auto relative">
                <p className="text-4xl">Order {orderId}</p>

                <p>Order No: {orderTrack && orderTrack.order_po_no ? orderTrack.order_po_no : "Not Set"}</p>

                <p>Payment: {paymentDetails && paymentDetails.status ? (paymentDetails.status === 1 ? "Cash - Success" : "Cash - Error") : "Account"}</p>
                
                {comments?.map((comment) => (
                    <p key={comment.id}>Comment: {comment.Text}</p>
                ))}

                

                {/* <p className="mt-5 w-1/3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p> */}
                <div className="flex absolute top-0 right-0">

                    <button className="inline-flex items-right px-12 py-2 ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_hover  uppercase mt-10" onClick={() => repeatThisOrder(orderItems)}>
                        repeat order
                    </button>
                    <button className="inline-flex items-right px-12 py-2 ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_hover uppercase mt-10" onClick={() => createTemplate(orderItems)}>
                        Make template
                    </button>
                </div>
            </div>

            <div className="container mx-auto flex mt-14">


                <table {...getTableProps()} className="mb-10 w-full" style={{ borderCollapse: "separate", borderSpacing: "0 1em" }}>
                    <thead>
                        {
                            headerGroups.map(headerGroup => (

                                <tr {...headerGroup.getHeaderGroupProps()} className="bg-gray-100 bg-gray-100 flex flex-col flex-no wrap md:table-row w-full">
                                    {
                                        headerGroup.headers.map(column => (

                                            <th {...column.getHeaderProps()} className="lg:px-5 lg:py-5 font-light">
                                                {column.render('Header')}
                                            </th>
                                        ))}
                                </tr>
                            ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            page.map(row => {

                                prepareRow(row)
                                return (

                                    <tr {...row.getRowProps()} className="bg-white rounded mt-5 bg-white rounded mt-5 flex flex-col flex-no wrap md:table-row" >
                                        {
                                            row.cells.map(cell => {

                                                return (
                                                    <td {...cell.getCellProps()} className="mx-auto text-center relative lg:border-r md:border-r border-slate-300">

                                                        {(cell.render('Cell').props.cell.column.id === "col2" || cell.render('Cell').props.cell.column.id === "col4") ? <p className="text-center px-5 py-5 text-gray-500">{cell.render('Cell')}</p> : cell.render('Cell')}
                                                    </td>
                                                )
                                            })}
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>


            </div>

        </div>
    )
}