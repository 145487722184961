import { Formik, Form } from "formik";
import axios from "axios";
import { useDispatch } from 'react-redux';
import CustomButton from "../../app/common/form/CustomButton";
import CustomText from "../../app/common/form/CustomText";
import CustomTextArea from "../../app/common/form/CustomTextArea";
import GreyHeading from "../../app/common/text/GreyHeading";
import { openModal } from "../../app/common/modals/modalReducer";

export function ContactForm() {
    const dispatch = useDispatch();
    const initialValues = {
        name: "",
        surname: "",
        organisation: "",
        telephone: "",
        fax: "",
        email: "",
        message: "",
    };

    return (
        <div className="bg-white">
            <Formik
                initialValues={initialValues}
                enableReinitialize="true"
                onSubmit={async (values, { setSubmitting, setStatus }) => {
                    try {
                        await axios.post(process.env.REACT_APP_API_URL + "contact-us", values)
                            .then(() => {
                                dispatch(openModal({
                                    modalType: "ModalNotification", modalProps: {
                                        type: 'success',
                                        heading: 'Success',
                                        body: 'Your message has been sent',
                                        closeText: 'Close',
                                    }
                                }));
                            });
                    } catch (error) {
                        dispatch(openModal({
                            modalType: "ModalNotification", modalProps: {
                                type: 'error',
                                heading: 'Something went wrong',
                                body: 'Please try again',
                                closeText: 'Close',
                            }
                        }))
                    }
                }}
            >
                {({ isSubmitting, status }) => (
                    <Form className="my-8 space-y-6 px-4 sm md:max-w-2xl lg:max-w-4xl xl:max-w-5xl m-auto" action="#" method="POST">
                        <GreyHeading text="Send us a message" />

                        <div className="sm:grid sm:grid-cols-2 sm:gap-4">
                            <CustomText label="Name" type="text" name="name" />
                            <CustomText label="Surname" type="text" name="surname" />
                        </div>
                        <div className="sm:grid sm:grid-cols-2 sm:gap-4">
                            <CustomText label="Organisation" type="text" name="organisation" />
                            <CustomText label="Telephone" type="text" name="telephone" />
                        </div>
                        <div className="sm:grid sm:grid-cols-2 sm:gap-4">
                            <CustomText label="Fax" type="text" name="fax" />
                            <CustomText label="Email" type="email" name="email" />
                        </div>
                        <div className="flex justify-center">
                            <CustomTextArea label="Message" name="message" />
                        </div>
                        <div className="text-center">
                            <CustomButton
                                className={"bg-lk_red text-white hover:bg-lk_red_hover uppercase border_radius_4 py-3 px-8 m-auto text-base"}
                                disabled={isSubmitting}
                                submitBool={isSubmitting}
                                type="submit"
                                text={"Submit Message"}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}