import React from "react";
import CustomHeader from "../../../app/common/elements/CustomHeader";
import CustomStep from "../../../app/common/elements/CustomStep";

export default function TipsToImproveOrderingPage() {
    const productNotAvailable = [
        {text: "Use the active ingredient to search for an alternative available", count: "01"},
        {text: "Search for alternative in the same size for example 21G needles", count: "02"},
        {text: "If there is no product available, one can still place the order which will automatically be placed on back order. Once this product is in stock Lakato will be in contact.", count: "03"}
    ];

    const cantFindProduct = [
        {text: "Search under pack size Search under the product code for example the Hills product code. Deactivate “My Range”", count: "01"},
        {text: "Failing the above, call your Lakato sales person to assist.", count: "02"},
    ];

    const quickSearch = [
        {text: "Use “My Range” option to browse through products ordered over the last 6 months.", count: "01"}
    ];

    const orderReceived = [
        {text: "Search under web history.", count: "01"},
        {text: "A sales order number will appear next to your order date confirming that Lakato has received your order.", count: "02"},
        {text: "Should no sales order number appear next to your order date, please contact Lakato immediately.", count: "03"}
    ];

    return (
        <div className="bg-lk_white_backround">
            <CustomHeader heading="TIPS TO IMPROVE ORDERING" breadcrumbs={[
                {name: "Home", href: '/', count: 1},
                {name: "Online Ordering", href: '/online-ordering', count: 2},
                {name: "Tips to improve ordering", href: '/online-ordering/tips-to-improve-ordering', count: 3},
            ]}/>

            <p className="text-lk_grey_one text-2xl sm:text-3xl lg:text-5xl mx-auto mt-12 text-center max-w-2xl lg:max-w-4xl xl:max-w-5xl">Product Not available</p>
            <div className="flex flex-col lg:flex-row my-20 items-center">
                <img src="/images/laptopMockupTransparent.png" alt="Lakato Mockup" className="lg:w-1/2" />
                <div className="flex flex-wrap max-w-2xl mx-auto">
                    {productNotAvailable.map((step) => (
                        <CustomStep
                            key={step.text}
                            text={step.text}
                            count={step.count}
                            footnote={step.footnote}
                            animateDirection="right"
                        />
                    ))}
                </div>
            </div>

            <div className="bg-lk_grey_background_dark_d9 py-20">
                <p className="text-lk_grey_one text-2xl sm:text-3xl lg:text-5xl mx-auto text-center max-w-2xl lg:max-w-4xl xl:max-w-5xl">Can't find the product</p>
                <div className="flex flex-col lg:flex-row my-20 items-center">
                    <div className="flex flex-wrap max-w-2xl m-auto order-2 lg:order-1">
                        {cantFindProduct.map((step) => (
                            <CustomStep
                                key={step.text}
                                text={step.text}
                                count={step.count}
                                footnote={step.footnote}
                                animateDirection="left"
                            />
                        ))}
                    </div>
                    <img src="/images/laptopMockupTransparent.png" alt="Lakato Mockup" className="order-1 lg:order-2 lg:w-1/2" />
                </div>
            </div>
            
            <p className="text-lk_grey_one text-2xl sm:text-3xl lg:text-5xl mx-auto mt-12 text-center max-w-2xl lg:max-w-4xl xl:max-w-5xl">Quick Search</p>
            <div className="lg:flex my-20 items-center">
                <img src="/images/laptopMockupTransparent.png" alt="Lakato Mockup" className="lg:w-1/2" />
                <div className="flex flex-wrap max-w-2xl mx-auto">
                    {quickSearch.map((step) => (
                        <CustomStep
                            key={step.text}
                            text={step.text}
                            count={step.count}
                            footnote={step.footnote}
                            animateDirection="right"
                        />
                    ))}
                </div>
            </div>

            <div className="bg-lk_grey_background_dark_d9 py-20">
                <p className="text-lk_grey_one text-2xl sm:text-3xl lg:text-5xl mx-auto text-center max-w-2xl lg:max-w-4xl xl:max-w-5xl">Has my order been received by Lakato</p>
                <div className="flex flex-col lg:flex-row my-20 items-center">
                    <div className="flex flex-wrap max-w-2xl m-auto order-2 lg:order-1">
                        {orderReceived.map((step) => (
                            <CustomStep
                                key={step.text}
                                text={step.text}
                                count={step.count}
                                footnote={step.footnote}
                                animateDirection="left"
                            />
                        ))}
                    </div>
                    <img src="/images/laptopMockupTransparent.png" alt="Lakato Mockup" className="order-1 lg:order-2 lg:w-1/2" />
                </div>
            </div>
        </div>
    )
}
