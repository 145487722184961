import { useState } from 'react';
import { Formik, Form } from "formik";
import { useSelector } from "react-redux";
import CustomButton from "../../../app/common/form/CustomButton";

export default function SystemSettings(props) {
    const { currentUser } = useSelector((state) => state.auth);

    var initialValues = {
        system_offline: 0,
        offline_message: ""
    };

    return (
        <div className="container mx-auto">
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validate={values => {
                    const errors = {};

                    return errors;
                }}
                onSubmit={async (values, { setErrors }) => {


                }}
            >
                {({ isSubmitting, errors, setFieldValue }) => (
                    <Form className="my-8 space-y-6 px-4 sm md:max-w-2xl lg:max-w-4xl xl:max-w-5xl m-auto" action="#" method="POST">

                        <div className="grid grid-cols-2 gap-4">

                            from date

                            to date

                            offline message

                        </div>

                        {errors.message && <p className="text-lk_red text-lg">{errors.message}</p>}

                        {currentUser && currentUser.isAdmin === 1 ?
                            <CustomButton
                                disabled={isSubmitting}
                                submitBool={isSubmitting}
                                type="submit"
                                text="Update"
                                className="lkbutton bg-lk_red hover:bg-lk_red_hover w-full border_radius_10"
                            />
                            : ""}

                    </Form>
                )}
            </Formik>
        </div>
    )
}