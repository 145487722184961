import React from "react";

export default function ServicesImageCard({image, imageAlt, textOne, textTwo}) {
    return (
        <div className="p-4 lg:px-32 relative lg:h-cta_medium_height max-w-7xl mx-auto">
            <img
                src={image}
                alt={imageAlt}
                className="my-4 sm:my-0 shadow-card_border border_radius_10 lg:absolute lg:top-1/4 left-10 xl:left-36 bg-white z-0 md:w-contact_card lg:w-about_us_card lg:h-about_us_card mx-auto"
            />
            <p className="my-4 sm:my-0 mx-auto text-lk_grey_two text-sm sm:text-md xl:text-lg shadow-card_border border_radius_10 p-8 sm:py-12 sm:px-12 lg:px-28 lg:absolute lg:right-10 xl: xl:right-36 z-10 md:w-contact_card lg:w-about_us_card align-center whitespace-normal bg-white">
                {textOne}<br/><br/>{textTwo}
            </p>
        </div>
    )
}