import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { usePagination, useTable, useSortBy } from 'react-table';
import AdminSearchForm from '../../../forms/AdminSearchForm';
import AdminNav from '../../../nav/AdminNav';
import CustomButton from '../../../../app/common/form/CustomButton';


export function Reports(props) {

    const { currentUser } = useSelector((state) => state.auth);

    let pathname = props.location.pathname;
    let newPathname = pathname.replace("/", "");
    let pageName = newPathname.replaceAll("-", " ");

    const [cartTotal, setCartTotal] = useState([]);
    const [loadingCust, setLoadingCust] = useState(0)
    const [loadingSku, setLoadingSku] = useState(0)
    const [loadingSearchZero, setLoadingSearchZero] = useState(0)
    const [loadingMostSearch, setLoadingMostSearch] = useState(0)
    const [loadingOpenCart, setLoadingOpenCart] = useState(0)
    const [loadingLogin, setLoadingLogin] = useState(0)
    const [loadingOrder, setLoadingOrder] = useState(0)

    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")

    useEffect(() => {
        setCartTotal(
            parseFloat(window.sessionStorage.getItem("itemsInBasket"))
        );
    }, []);

    async function downloadCustomers(fromDate, toDate){
        setLoadingCust(1)

        let script = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}top-customers-excel`,
            headers: { Authorization: `Bearer ${currentUser.token}`},
            data: {
                userId: currentUser.id,
                fromDate: fromDate,
                toDate: toDate,
            },
        });

        window
            .open(process.env.REACT_APP_URL + script.data, "_blank")
            .focus();

        setLoadingCust(0)
    }

    async function downloadSku(fromDate, toDate){
        setLoadingSku(1)

        let script = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}top-sku-excel`,
            headers: { Authorization: `Bearer ${currentUser.token}`},
            data: {
                userId: currentUser.id,
                fromDate: fromDate,
                toDate: toDate,
            },
        });

        window
            .open(process.env.REACT_APP_URL + script.data, "_blank")
            .focus();

        setLoadingSku(0)
    }

    async function downloadZeroSearch(fromDate, toDate){
        setLoadingSearchZero(1)
        
        let script = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}zero-search-excel`,
            headers: { Authorization: `Bearer ${currentUser.token}`},
            data: {
                userId: currentUser.id,
                fromDate: fromDate,
                toDate: toDate,
            },
        });

        window
            .open(process.env.REACT_APP_URL + script.data, "_blank")
            .focus();

        setLoadingSearchZero(0)
    }

    async function downloadMostSearch(fromDate, toDate){
        setLoadingMostSearch(1)
        
        let script = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}most-search-excel`,
            headers: { Authorization: `Bearer ${currentUser.token}`},
            data: {
                userId: currentUser.id,
                fromDate: fromDate,
                toDate: toDate,
            },
        });

        window
            .open(process.env.REACT_APP_URL + script.data, "_blank")
            .focus();

        setLoadingMostSearch(0)
    }

    async function downloadOpenCart(fromDate, toDate){
        setLoadingOpenCart(1)
        
        let script = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}open-cart-excel`,
            headers: { Authorization: `Bearer ${currentUser.token}`},
            data: {
                userId: currentUser.id,
                fromDate: fromDate,
                toDate: toDate,
            },
        });

        window
            .open(process.env.REACT_APP_URL + script.data, "_blank")
            .focus();

        setLoadingOpenCart(0)
    }

    async function downloadLogin(fromDate, toDate){
        setLoadingLogin(1);
        
        let script = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}logins-excel`,
            headers: { Authorization: `Bearer ${currentUser.token}`},
            data: {
                userId: currentUser.id,
                fromDate: fromDate,
                toDate: toDate,
            },
        });

        window
            .open(process.env.REACT_APP_URL + script.data, "_blank")
            .focus();

        setLoadingLogin(0)
    }

    async function downloadOrders(fromDate, toDate){
        setLoadingOrder(1);
        
        let script = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}orders-placed-excel`,
            headers: { Authorization: `Bearer ${currentUser.token}`},
            data: {
                userId: currentUser.id,
                fromDate: fromDate,
                toDate: toDate,
            },
        });

        window
            .open(process.env.REACT_APP_URL + script.data, "_blank")
            .focus();

            setLoadingOrder(0)
    }

    



    return (
        <>
        <div className="bg-gray-50">
            <AdminSearchForm
                heading={pageName}
                color="text-gray-500"
                header_color="bg-gradient-to-b from-gray-300"
                crumb_color="text-black"
                breadcrumbs={[
                    { name: "Home", href: "/", count: 1 },
                    { name: pageName, href: pathname, count: 2 },
                ]}
            />
            <br/><br/>

            <div className="container mx-auto">
                <p className="text-3xl ml-5 mb-5">Reports:</p>
                <div>
                    <label className="ml-5 mb-5"> From Date: </label><input type="date" name="fromDate" onChange={(v) => setFromDate(v.target.value)} />
                    <label className="ml-5 mb-5"> To Date: </label><input type="date" name="toDate" onChange={(v) => setToDate(v.target.value)} />
                    <br/>
                </div>
                <div className="flex flex-wrap mt-5">
                    <div className="mx-2">
                        <CustomButton
                            type="submit"
                            text={"Top 10 customers"}
                            className="lkbutton bg-lk_red hover:bg-lk_red_hover w-full border_radius_10 mb-5 ml-4"
                            submitBool={loadingCust}
                            onClick={() => downloadCustomers(fromDate, toDate)}
                        />
                    </div>
                    <div className="mx-2">
                        <CustomButton
                            type="submit"
                            text={"Top 10 SKU's"}
                            className="lkbutton bg-lk_red hover:bg-lk_red_hover w-full border_radius_10 mb-5 ml-4"
                            submitBool={loadingSku}
                            onClick={() => downloadSku(fromDate, toDate)}
                        />
                    </div>
                    <div className="mx-2">
                        <CustomButton
                            type="submit"
                            text={"0 return searches"}
                            className="lkbutton bg-lk_red hover:bg-lk_red_hover w-full border_radius_10 mb-5 ml-4"
                            submitBool={loadingSearchZero}
                            onClick={() => downloadZeroSearch(fromDate, toDate)}
                        />
                    </div>
                    <div className="mx-2">
                        <CustomButton
                            type="submit"
                            text={"Top 10 most searched"}
                            className="lkbutton bg-lk_red hover:bg-lk_red_hover w-full border_radius_10 mb-5 ml-4"
                            submitBool={loadingMostSearch}
                            onClick={() => downloadMostSearch(fromDate, toDate)}
                        />
                    </div>
                    <div className="mx-2">
                        <CustomButton
                            type="submit"
                            text={"Open Carts"}
                            className="lkbutton bg-lk_red hover:bg-lk_red_hover w-full border_radius_10 mb-5 ml-4"
                            submitBool={loadingOpenCart}
                            onClick={() => downloadOpenCart(fromDate, toDate)}
                        />
                    </div>
                    <div className="mx-2">
                        <CustomButton
                            type="submit"
                            text={"Logins per user"}
                            className="lkbutton bg-lk_red hover:bg-lk_red_hover w-full border_radius_10 mb-5 ml-4"
                            submitBool={loadingLogin}
                            onClick={() => downloadLogin(fromDate, toDate)}
                        />
                    </div>
                    <div className="mx-2">
                        <CustomButton
                            type="submit"
                            text={"Value of orders placed"}
                            className="lkbutton bg-lk_red hover:bg-lk_red_hover w-full border_radius_10 mb-5 ml-4"
                            submitBool={loadingOrder}
                            onClick={() => downloadOrders(fromDate, toDate)}
                        />
                    </div>
                </div>
            </div>

        </div>


        

    </>
    )    
}