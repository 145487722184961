import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faHome, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { openModal } from "../../../app/common/modals/modalReducer";
import AdminNav from "../AdminNav";
import axios from "axios";
import { signOutUser } from "../../auth/authActions";
import { faBan, faSearch, faStop, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Formik } from "formik";
import { faDrawCircle, faUserFriends } from "@fortawesome/pro-regular-svg-icons";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { current } from "@reduxjs/toolkit";
import { getCartTotal } from "./cartActions";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function NavBar(props) {
    const { currentUser } = useSelector((state) => state.auth);
    const { cartTotal } = useSelector((state) => state.cart);
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    let history = useHistory();
    const location = useLocation();

    // const [cartTotal, setCartTotal] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [checkingCart, setCheckingCart] = useState(false);
    const [serverVersion, setServerVersion] = useState("");

    function clearSearch() {
        setSearchValue("");
    }

    useEffect(() => {
        // setInterval(() => {
        //     setCartTotal(parseFloat(window.sessionStorage.getItem("itemsInBasket")))
        //   }, 100)

        // return () => clearInterval(intervalId);

        // getCartTotal();

        axios.defaults.baseURL = process.env.REACT_APP_API_URL;

        const echo = new Echo({
            broadcaster: "pusher",
            key: process.env.REACT_APP_MIX_ABLY_PUBLIC_KEY,

            wsHost: process.env.REACT_APP_MIX_ABLY_URL,
            wssHost: process.env.REACT_APP_MIX_ABLY_URL,

            wsPort: process.env.REACT_APP_MIX_ABLY_PORT,
            wssPort: process.env.REACT_APP_MIX_ABLY_PORT,

            disableStats: true,
            encrypted: false,
            enabledTransports: ["ws", "wss"],
            forceTLS: false,

            enableLogging: true,
        });

        if (currentUser) {
            // echo.channel("cart.list")
            //     .subscribed(() => {
            //         console.log("cart list subscribed");
            //         getCartTotal();
            //     })
            //     .listen("CartList", (data) => {
            //         getCartTotal();
            //     });
            dispatch(getCartTotal(currentUser));
        }

        if (serverVersion === "") {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}version`,
            }).then((res) => {
                setServerVersion(res.data);
            });
        }

        return () => {
            echo.disconnect();
        };

        // setInterval(() => {
        //     if (checkingCart === false) {
        //         getCartTotal();
        //     }
        // }, 5000);
    }, [currentUser, cartTotal]);

    // async function getCartTotal() {
    //     console.log("current user", currentUser);

    //     if (currentUser === null && window.sessionStorage.getItem("loggedIn") == "true") {
    //         console.log("getting here");

    //         window.location.reload();
    //     }

    //     if (currentUser) {
    //         try {
    //             setCheckingCart(true);

    //             let amount = await axios({
    //                 method: "post",
    //                 url: `${process.env.REACT_APP_API_URL}total-items-in-cart`,
    //                 headers: { Authorization: `Bearer ${currentUser.token}` },
    //                 data: {
    //                     id: currentUser.id,
    //                     customer_code: currentUser.customer_code,
    //                 },
    //             });

    //             console.log("amount from server", amount);

    //             // setCartTotal(amount.data);

    //             setInterval(() => {
    //                 setCheckingCart(false);
    //             }, 3000);
    //         } catch (error) {
    //             if (error.response && error.response.status === 401) {
    //                 signout();
    //             } else {
    //                 // Handle other errors
    //                 console.log("An error occurred:", error.message);
    //             }
    //         }
    //     }
    // }

    if (currentUser && new Date(new Date(currentUser.logged_in_at).toDateString()) < new Date(new Date().toDateString())) {
        signout();
    }

    async function signout() {
        window.sessionStorage.setItem("loggedIn", false);
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}logout`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
        })
            .then((res) => {
                dispatch(signOutUser());
            })
            .catch((error) => {
                console.log(error);
                dispatch(signOutUser());
            });
    }

    let image = !currentUser ? "" : `${process.env.REACT_APP_URL}/${currentUser.profile_image}`;
    let imgPlaceholder = !currentUser ? "" : `${process.env.REACT_APP_URL}/images/placeholder.png`;

    // const user = {
    //     name: "Tom Cook",
    //     email: "tom@example.com",
    //     imageUrl:
    //         "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    // };
    // const userNavigation = [
    //     { name: "Your Profile", href: "#" },
    //     { name: "Settings", href: "#" },
    //     { name: "Sign out", href: "#" },
    // ];
    const mobileOutnavigation = [
        { name: "Home", href: "/", current: pathname === "/" ? true : false },
        {
            name: "About Us",
            href: "/about-us",
            current: pathname === "/about-us" ? true : false,
        },
        {
            name: "Services",
            href: "/services",
            current: pathname === "/services" ? true : false,
            children: [
                {
                    name: "Wholesale",
                    href: "/services/wholesale",
                    current: pathname === "/services/wholesale" ? true : false,
                },
                {
                    name: "Warehousing & Distribution",
                    href: "/services/warehousing-and-distribution",
                    current: pathname === "/services/warehousing-and-distribution" ? true : false,
                },
                {
                    name: "Repacking",
                    href: "/services/repacking",
                    current: pathname === "/services/repacking" ? true : false,
                },
                {
                    name: "Logistics",
                    href: "/services/logistics",
                    current: pathname === "/services/logistics" ? true : false,
                },
            ],
        },
        {
            name: "Online Ordering",
            href: "/online-ordering",
            current: pathname === "/online-ordering" ? true : false,
        },
        {
            name: "Products",
            href: "/products",
            current: pathname === "/products" ? true : false,
        },
        {
            name: "Contact Us",
            href: "/contact-us",
            current: pathname === "/contact-us" ? true : false,
        },
    ];

    const mobileNavLoggedIn = [
        { name: "Home", href: "/", current: pathname === "/" ? true : false },
        {
            name: "Shop",
            href: "/shop",
            current: pathname === "/shop" ? true : false,
        },
        {
            name: "Favourites",
            href: "/shop-favourites",
            current: pathname === "/shop-favourites" ? true : false,
        },
        {
            name: "Order Templates",
            href: "/order-templates",
            current: pathname === "/order-templates" ? true : false,
        },
        {
            name: "Promotions",
            href: "/promotions",
            current: pathname === "/promotions" ? true : false,
        },
        {
            name: "Order History",
            href: "/orders",
            current: pathname === "/orders" ? true : false,
        },
        {
            name: "Enquiries",
            href: "/enquiries",
            current: pathname === "/enquiries" ? true : false,
        },
        {
            name: "Custom Codes",
            href: "/custom_codes",
            current: pathname === "/custom_codes" ? true : false,
        },
        {
            name: "View Cart",
            href: "/view-cart",
            current: pathname === "/view-cart" ? true : false,
        },
    ];

    addAdminSlides();

    async function addAdminSlides() {
        if (currentUser && currentUser.isAdmin === 1 && currentUser.isSales === 0 && currentUser.isLakato === 0) {
            mobileNavLoggedIn.push({
                name: "Promotion Manager",
                href: "/promotion-management",
                current: pathname === "/promotion-management" ? true : false,
            });
            mobileNavLoggedIn.push({
                name: "Enquiries History",
                href: "/enquiries-history",
                current: pathname === "/enquiries-history" ? true : false,
            });
            mobileNavLoggedIn.push({
                name: "User Manager",
                href: "/user-management",
                current: pathname === "/user-management" ? true : false,
            });
            mobileNavLoggedIn.push({
                name: "Reports",
                href: "/reports",
                current: pathname === "/reports" ? true : false,
            });
        }

        if (currentUser && currentUser.main_admin === true) {
            mobileNavLoggedIn.push({
                name: "User Manager",
                href: "/user-management",
                current: pathname === "/user-management" ? true : false,
            });
        }

        mobileNavLoggedIn.push({
            name: "V 1.6.4",
            href: "/shop",
            current: false,
        });
    }

    const navigation = !currentUser ? mobileOutnavigation : mobileNavLoggedIn;
    const navigationActive = !currentUser ? "mobileOutnavigation" : "mobileNavLoggedIn";

    return (
        <>
            {serverVersion !== "V 1.6.4" && (
                <p className="bg-red-500 text-center text-white">You are currently on an older version, please refresh your page (F5) or contact Lakato support. - Latest Version = {serverVersion}</p>
            )}
            <Disclosure as="header" className="bg-white shadow-md z-20 sticky top-0">
                {({ open, close }) => (
                    <>
                        <div className="">
                            {/* <div className="bg-lk_red text-white text-center">
                            Currently the system is under going maintenance and is not available for placing of orders
                        </div> */}

                            <div className="px-2 lg:px-8 sm:px-4 max-w-7xl mx-auto relative h-16 flex justify-between">
                                <div className="relative z-10 px-2 flex lg:px-0">
                                    <div className="flex-shrink-0 flex items-center">
                                        <Link as="a" to="/">
                                            <img className="block h-8 w-auto" src="/images/logo.svg" alt="Lakato" />
                                        </Link>
                                    </div>
                                </div>

                                <div className="relative z-10 flex items-center lg:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500">
                                        <span className="sr-only">Open menu</span>
                                        {open ? <XIcon className="block h-6 w-6" aria-hidden="true" /> : <MenuIcon className="block h-6 w-6" aria-hidden="true" />}
                                    </Disclosure.Button>
                                </div>
                                <div className="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
                                    <button className="inline-flex items-center px-12 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover">
                                        COVID 19
                                    </button>

                                    {!currentUser ? (
                                        <>
                                            {/* <button className="inline-flex items-center px-12 py-2 ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_two hover:bg-lk_grey_two_hover">
                                            REGISTER
                                        </button> */}
                                            <button
                                                className="inline-flex items-center px-12 py-2 ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_hover"
                                                onClick={() =>
                                                    dispatch(
                                                        openModal({
                                                            modalType: "ModalLogin",
                                                        })
                                                    )
                                                }
                                            >
                                                LOGIN
                                            </button>
                                        </>
                                    ) : (
                                        // <Link
                                        //     as="a"
                                        //     to="/shop"
                                        // >
                                        //     <button
                                        //     className="inline-flex items-center px-12 py-2 ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_hover"
                                        // >
                                        //     DASHBOARD
                                        // </button>
                                        // </Link>
                                        <>
                                            <Link aria-current="page" as="a" to="/account" className="flex">
                                                {currentUser.profile_image !== null ? (
                                                    <img className="inline-block h-9 w-9 rounded-full ml-5" src={image} alt="" />
                                                ) : (
                                                    <img className="inline-block h-9 w-9 rounded-full ml-3" src={imgPlaceholder} alt="" />
                                                )}
                                                <p className="ml-3 uppercase font-medium mt-2" style={{ width: "270px" }}>
                                                    {currentUser.ShipToAddr1 && currentUser.ShipToAddr1.length > 0 ? currentUser.ShipToAddr1 : `${currentUser.name} ${currentUser.last_name}`} -{" "}
                                                    {currentUser.customer_code && currentUser.isCustomer === 1
                                                        ? currentUser.customer_code
                                                        : currentUser.customer_code.split(",").length > 1
                                                        ? "Admin"
                                                        : currentUser.customer_code}
                                                </p>
                                            </Link>

                                            {currentUser && currentUser.multi_account === true ? (
                                                <Link
                                                    className="inline-flex items-center px-3 py-3 ml-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_hover cursor-pointer"
                                                    as="a"
                                                    to="/select-profile"
                                                >
                                                    <FontAwesomeIcon icon={faUserFriends} />
                                                </Link>
                                            ) : (
                                                ""
                                            )}

                                            <div
                                                className="inline-flex items-center px-12 py-2 ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_hover cursor-pointer"
                                                onClick={() => signout()}
                                            >
                                                Logout
                                            </div>

                                            <Link
                                                className="inline-flex items-center px-6 py-1 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover ml-3"
                                                as="a"
                                                to="/view-cart"
                                            >
                                                <div className="h-8 w-8 rounded-full bg-white mr-4">
                                                    {cartTotal > 0 ? (
                                                        <>
                                                            <FontAwesomeIcon className={"text-black cursor-pointer text-base ml-1.5 mt-2"} icon={faShoppingCart} />
                                                            <div className="h-5 w-5 rounded-full bg-lk_red ml-7 -mt-8 text-center">
                                                                <p className="text-xs pt-0.5">{cartTotal}</p>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <FontAwesomeIcon className={"text-black cursor-pointer text-base ml-1.5 mt-2"} icon={faShoppingCart} />
                                                    )}
                                                </div>
                                                View Cart
                                            </Link>
                                        </>
                                    )}
                                </div>
                            </div>
                            <nav className="hidden lg:flex lg:space-x-8 lg:items-center lg:justify-center" aria-label="Global">
                                {!currentUser ? (
                                    <>
                                        <Link
                                            className={"text-lk_grey_one hover:text-lk_grey_two xl:pr-32"}
                                            as="a"
                                            to="/"
                                            aria-current={"home" ? "page" : undefined}
                                            onClick={() => {
                                                close();
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faHome} />
                                        </Link>
                                        {navigation.map((item) => {
                                            if (item.children) {
                                                return (
                                                    <div className="dropdown inline px-4 tracking-wide relative" key={item.name}>
                                                        <Link
                                                            className={classNames(
                                                                item.current ? "text-lk_dark_grey_button" : "text-lk_grey_two hover:text-lk_dark_grey_button",
                                                                "py-2 px-3 inline-flex items-center text-sm font-medium uppercase"
                                                            )}
                                                            as="a"
                                                            to={item.href}
                                                            onClick={() => {
                                                                close();
                                                            }}
                                                            aria-current={item.current ? "page" : undefined}
                                                        >
                                                            {item.name} <FontAwesomeIcon className="ml-2" icon={faChevronRight} />
                                                        </Link>
                                                        <div className="dropdown-menu top-6 absolute hidden h-auto pt-4 z-10">
                                                            <ul className="block w-full bg-white shadow p-2">
                                                                {item.children.map((child) => (
                                                                    <li className="py-1" key={child.name}>
                                                                        <Link
                                                                            className={classNames(
                                                                                child.current ? "text-lk_dark_grey_button" : "text-lk_grey_two hover:text-lk_dark_grey_button",
                                                                                "py-2 px-3 inline-flex items-center text-sm font-medium uppercase w-40"
                                                                            )}
                                                                            as="a"
                                                                            to={child.href}
                                                                            onClick={() => {
                                                                                close();
                                                                            }}
                                                                            aria-current={child.current ? "page" : undefined}
                                                                        >
                                                                            {child.name}
                                                                        </Link>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                );
                                            } else if (item.name !== "Home") {
                                                return (
                                                    <Link
                                                        key={item.name}
                                                        className={classNames(
                                                            item.current ? "text-lk_dark_grey_button" : "text-lk_grey_two hover:text-lk_dark_grey_button",
                                                            "py-2 px-3 inline-flex items-center text-sm font-medium uppercase"
                                                        )}
                                                        as="a"
                                                        to={item.href}
                                                        onClick={() => {
                                                            close();
                                                        }}
                                                        aria-current={item.current ? "page" : undefined}
                                                    >
                                                        {item.name}
                                                    </Link>
                                                );
                                            }
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <AdminNav />
                                    </>
                                )}
                            </nav>
                        </div>

                        <Disclosure.Panel as="nav" className="lg:hidden" aria-label="Global">
                            <div className="pt-2 pb-3 px-2 space-y-1">
                                {navigation.map((item) => (
                                    <div key={item.name}>
                                        <Link
                                            key={item.name}
                                            className={classNames(
                                                item.current ? "text-lk_dark_grey_button" : "text-lk_grey_two hover:bg-gray-50 hover:text-gray-900",
                                                "block rounded-md py-2 px-3 text-base font-medium"
                                            )}
                                            as="a"
                                            to={item.href}
                                            onClick={() => {
                                                close();
                                            }}
                                            aria-current={item.current ? "page" : undefined}
                                        >
                                            {item.name}
                                        </Link>
                                        {item.children &&
                                            item.children.map((child) => (
                                                <Link
                                                    key={child.name}
                                                    className={classNames(
                                                        child.current ? "text-lk_dark_grey_button" : "text-lk_grey_two hover:bg-gray-50 hover:text-gray-900",
                                                        "block rounded-md py-2 px-3 text-base font-medium"
                                                    )}
                                                    as="a"
                                                    to={child.href}
                                                    onClick={() => {
                                                        close();
                                                    }}
                                                    aria-current={child.current ? "page" : undefined}
                                                >
                                                    {child.name}
                                                </Link>
                                            ))}
                                    </div>
                                ))}
                            </div>

                            {/* <div className="border-t border-gray-200 pt-4 pb-3">
                            <div className="px-4 flex items-center">
                                <div className="flex-shrink-0">
                                    <img
                                        className="h-10 w-10 rounded-full"
                                        src={user.imageUrl}
                                        alt=""
                                    />
                                </div>
                                <div className="ml-3">
                                    <div className="text-base font-medium text-gray-800">
                                        {user.name}
                                    </div>
                                    <div className="text-sm font-medium text-gray-500"> 
                                        {user.email}
                                    </div>
                                </div>
                                <button className="ml-auto flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    <span className="sr-only">
                                        View notifications
                                    </span>
                                    <BellIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                            <div className="mt-3 px-2 space-y-1">
                                {userNavigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                        </div> */}
                        </Disclosure.Panel>
                        {navigationActive === "mobileNavLoggedIn" ? (
                            <>
                                <div className="lg:hidden">
                                    <div className="flex flex-row-reverse">
                                        <Link
                                            className="inline-flex items-center px-6 py-1 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover ml-3 mb-5 mr-5"
                                            as="a"
                                            to="/view-cart"
                                        >
                                            <div className="h-8 w-8 rounded-full bg-white mr-4">
                                                {cartTotal > 0 ? (
                                                    <>
                                                        <FontAwesomeIcon className={"text-black cursor-pointer text-base ml-1.5 mt-2"} icon={faShoppingCart} />
                                                        <div className="h-5 w-5 rounded-full bg-lk_red ml-7 -mt-8">
                                                            <p className="text-xs ml-1 pt-0.5">{cartTotal}</p>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <FontAwesomeIcon className={"text-black cursor-pointer text-base ml-1.5 mt-2"} icon={faShoppingCart} />
                                                )}
                                            </div>
                                            View Cart
                                        </Link>
                                        <div
                                            className="inline-flex items-center px-12 py-2 ml-7 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_hover mb-5"
                                            onClick={() => signout()}
                                        >
                                            Logout
                                        </div>
                                    </div>
                                    <div>
                                        {currentUser && currentUser.multi_account === true ? (
                                            <Link
                                                className="inline-flex items-center px-12 py-2 ml-7 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_hover mb-5 w-80 justify-center"
                                                as="a"
                                                to="/select-profile"
                                            >
                                                Switch
                                            </Link>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    {location.pathname !== "/search-results" && (
                                        <div className="w-full mx-auto container">
                                            <Formik
                                                initialValues={{
                                                    searchBox: "",
                                                }}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    history.push({
                                                        pathname: "/search-results",
                                                        state: [searchValue, window.sessionStorage.getItem("categoryForSearch")],
                                                    });
                                                }}
                                            >
                                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="lg:flex lg:items-center lg:justify-center flex">
                                                            <div className="relative text-center w-full">
                                                                <div className="absolute left-3 top-2">
                                                                    <FontAwesomeIcon className={"text-gray-400 cursor-pointer text-base"} icon={faSearch} />
                                                                </div>
                                                                <div className="absolute top-2 right-5">
                                                                    <FontAwesomeIcon
                                                                        className={"text-gray-400 cursor-pointer text-base"}
                                                                        icon={faTimes}
                                                                        onClick={() => {
                                                                            setFieldValue("searchBox", "");
                                                                            clearSearch();
                                                                        }}
                                                                    />
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    name="searchBox"
                                                                    value={values.searchBox}
                                                                    className="w-full pl-10 pr-20 rounded-lg z-0 focus:shadow focus:outline-none"
                                                                    onChange={(values) => {
                                                                        setFieldValue("searchBox", values.target.value);
                                                                        setSearchValue(values.target.value);
                                                                    }}
                                                                    placeholder="Search for products..."
                                                                />
                                                            </div>

                                                            <button
                                                                type="submit"
                                                                disabled={isSubmitting}
                                                                loading={isSubmitting.toString()}
                                                                className="inline-flex items-center px-12 py-2 ml-2 lg:ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_hover mb-2 lg:mt-0"
                                                            >
                                                                <span className="w-20">
                                                                    GO &nbsp;
                                                                    {">"}
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </form>
                                                )}
                                            </Formik>
                                        </div>
                                    )}
                                </div>
                            </>
                        ) : (
                            <div className="flex flex-row-reverse lg:hidden">
                                <button
                                    className="inline-flex  items-center px-12 py-2 mr-3 mb-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_hover"
                                    onClick={() =>
                                        dispatch(
                                            openModal({
                                                modalType: "ModalLogin",
                                            })
                                        )
                                    }
                                >
                                    LOGIN
                                </button>
                            </div>
                        )}
                    </>
                )}
            </Disclosure>
        </>
    );
}
