import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import AdminSearchForm from "../../../forms/AdminSearchForm";
import { useTable, usePagination } from "react-table";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
    faExclamationCircle,
    faHeart as solidHeart,
    faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
import { openModal } from "../../../../app/common/modals/modalReducer";
import ReactTooltip from "react-tooltip";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

export default function SpecificTemplate(props) {
    let history = useHistory();
    let dispatch = useDispatch();

    let templateID = props.match.params.templateID;
    // const dispatch = useDispatch();

    const { currentUser } = useSelector((state) => state.auth);
    // const [cartTotal, setCartTotal] = useState([])
    const [templateDetails, setTemplateDetails] = useState([]);
    const [orderItems, setOrderItems] = useState([]);

    const allowedSups =
        currentUser.supplier_stock != null
            ? currentUser.supplier_stock.split(",").map(function (item) {
                  return item.trim();
              })
            : currentUser.supplier_stock;

    useEffect(() => {
        getOrder();
    }, [templateID]);

    async function getOrder() {
        let template = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}this-template`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                userId: currentUser.id,
                templateID: templateID,
            },
        });

        let templateDetails = template.data;
        setTemplateDetails(templateDetails);

        let items = template.data[0].items;
        let itemIds = [];

        await Object.keys(items).forEach(function (key) {
            itemIds.push(items[key].stock_code);
        });

        let productResults = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}get-favourite-products`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                userId: currentUser.id,
                priceCode:
                    currentUser.isCustomer === 1 &&
                    currentUser.customer_code !== null
                        ? currentUser.customer_code
                        : currentUser.customer_code === null ||
                          currentUser.customer_code.split(",").length > 1
                        ? "None"
                        : currentUser.customer_code,
                category: "",
                favIds: itemIds,
                branch: currentUser.branch,
            },
        });

        // console.log(orderDetails)

        let favouritesResults = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}favourites`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                userId: currentUser.id,
            },
        });

        let cartItems = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}get-items-in-cart`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                id: currentUser.id,
                priceCode: currentUser.customer_code,
                branch: currentUser.branch,
            },
        });

        let cartItemData = cartItems.data[1];

        let specialList = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}active-specials`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
            },
            data: {
                customer_code:
                    currentUser.isCustomer === 1 &&
                    currentUser.customer_code !== null
                        ? currentUser.customer_code
                        : currentUser.customer_code === null ||
                          currentUser.customer_code.split(",").length > 1
                        ? "None"
                        : currentUser.customer_code,
            },
        });

        let allSpecials = specialList.data;

        let favData = favouritesResults.data[0];
        let prodDataSubCode = favouritesResults.data[1];
        let favIds = [];

        await Object.keys(favData).forEach(function (key) {
            favIds.push(favData[key].favourite_product_id);
        });

        let allCodes = [];

        prodDataSubCode.forEach(function (subCode) {
            allCodes.push(subCode.stock_code);
        });

        let ordeItems = productResults.data;
        let allproducts = [];

        console.log(ordeItems);

        await Object.keys(ordeItems).forEach(async function (key) {
            let curfav = false;

            if (favIds.includes(ordeItems[key].StockCode.trim())) {
                curfav = true;
            }

            let display = "";

            if (
                prodDataSubCode.length > 0 &&
                allCodes.includes(ordeItems[key].StockCode)
            ) {
                console.log("sd");
                prodDataSubCode.forEach(function (subCode) {
                    if (subCode.stock_code === ordeItems[key].StockCode) {
                        display = subCode.custom_code;
                    }
                });
            }

            let inCart = "no";
            let total = 1;

            await Object.keys(cartItemData).forEach(function (index) {
                if (
                    cartItemData[index].stock_code === ordeItems[key].StockCode
                ) {
                    total = cartItemData[index].qty;
                    inCart = "yes";
                }
            });

            let promoId = [];
            let promoArr = [];

            allSpecials.forEach(function (promo) {
                let items = promo.items;
                let onPromo = [];
                items.forEach(function (item) {
                    if (
                        item.product_type === "1" &&
                        ordeItems[key].StockCode.trim() ===
                            item.product_stock_code
                    ) {
                        onPromo.push("yes");
                    }
                });

                onPromo.forEach(function (itemsPromo) {
                    if (itemsPromo !== undefined) {
                        promoArr.push(onPromo[0]);
                        if (onPromo[0] === "yes") {
                            promoId.push(promo.id);
                        }
                    }
                });
            });

            allproducts.push({
                col0: ordeItems[key].StockCode,
                col1: "",
                col2: ordeItems[key].StockCode,
                col3: ordeItems[key].Description,
                col4: ordeItems[key].InvWarehouse,
                col5: ordeItems[key].SellingPrice
                    ? "R " + parseFloat(ordeItems[key].SellingPrice).toFixed(2)
                    : "Free",
                col6: total,
                col7: curfav,
                col9: ordeItems[key].altStock,
                col10: ordeItems[key].AlternateUom,
                col11: inCart,
                col12: promoArr[0],
                col13: promoId[0],
                col14: display,
                col15: ordeItems[key].img,
                col23: ordeItems[key].UserField1,
                col24: ordeItems[key].AlternateKey2,
                col25: ordeItems[key].InvwarehouseQty,
                col26: ordeItems[key].Supplier,
            });
        });

        // console.log(allproducts);

        setOrderItems(allproducts);
    }

    async function addItemToCart(itemId, qty) {
        itemId = itemId.trim();

        let cartItem = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}add-items-to-cart`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                StockCode: itemId,
                id: currentUser.id,
                qty: qty,
                auto: 0,
                customer_code: currentUser.customer_code,
            },
        });

        if (cartItem.data === 1) {
            let CurrentCartAmount =
                window.sessionStorage.getItem("itemsInBasket");
            let NewAmount = parseFloat(CurrentCartAmount) + 1;
            window.sessionStorage.setItem("itemsInBasket", NewAmount);
        }

        toast.success("Item Added to Cart", {
            position: "bottom-right",
            autoClose: 5000,
            closeOnClick: true,
        });

        await getOrder();

        //   itemId = itemId.trim();
        //   let prevItemClicked = JSON.parse(
        //       window.sessionStorage.getItem("itemsClicked")
        //   );
        //   if (prevItemClicked !== null) {
        //       window.sessionStorage.setItem(
        //           "itemsClicked",
        //           JSON.stringify([...prevItemClicked, itemId])
        //       );
        //   } else {
        //       window.sessionStorage.setItem(
        //           "itemsClicked",
        //           JSON.stringify(itemId)
        //       );
        //   }

        //   let prevItemIds = JSON.parse(
        //       window.sessionStorage.getItem("itemsIdsInBasket")
        //   );

        //   if (!prevItemIds.includes(itemId)) {
        //       window.sessionStorage.setItem(
        //           "itemsIdsInBasket",
        //           JSON.stringify([...prevItemIds, itemId])
        //       );

        //       let CurrentCartAmount =
        //           window.sessionStorage.getItem("itemsInBasket");
        //       let NewAmount = parseFloat(CurrentCartAmount) + 1;
        //       window.sessionStorage.setItem("itemsInBasket", NewAmount);
        //       setCartTotal(NewAmount);

        //   } else {
        //       let totalItemsClicked = JSON.parse(
        //           window.sessionStorage.getItem("itemsClicked")
        //       );
        //       let thisItem = 0;

        //       await Object.keys(totalItemsClicked).forEach(function (key) {
        //           if (totalItemsClicked[key] === itemId) {
        //               thisItem += 1;
        //           }
        //       });

        //       let prevItemTotals = JSON.parse(
        //           window.sessionStorage.getItem("totalItemsInBasket")
        //       );
        //       if (prevItemTotals !== null) {
        //           window.sessionStorage.setItem(
        //               "totalItemsInBasket",
        //               JSON.stringify([...prevItemTotals, [thisItem, itemId]])
        //           );
        //       } else {
        //           window.sessionStorage.setItem(
        //               "totalItemsInBasket",
        //               JSON.stringify([[thisItem, itemId]])
        //           );
        //       }
        //   }
    }

    async function addFavourite(rowId) {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}add-new-favourite`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                rowId: rowId,
                userId: currentUser.id,
            },
        });

        getOrder();
    }

    async function removeFavourite(rowId) {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}remove-favourite`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                rowId: rowId,
                userId: currentUser.id,
            },
        });

        getOrder();
    }

    async function openPromo(value) {
        console.log(value);
        let activeSpecial = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}special-details`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
            },
            data: { promotionID: value },
        });

        console.log(activeSpecial.data);
        let specialData = [activeSpecial.data];
        let specialInfo = [];

        await Object.keys(specialData).forEach(async function (key) {
            let loopItems = specialData[key].items;
            let products = [];
            let free = [];

            await Object.keys(loopItems).forEach(async function (index) {
                if (loopItems[index].product_type === "1") {
                    products.push(loopItems[index].product_stock_code);
                }
                if (loopItems[index].product_type === "2") {
                    free.push(loopItems[index].product_stock_code);
                }
            });

            specialInfo.push({
                col0: specialData[key].id,
                col1: specialData[key].promotion_name,
                col2: specialData[key].from_date,
                col3: specialData[key].to_date,
                col4: specialData[key].discount_type,
                col5: specialData[key].free_amount,
                col6: specialData[key].discount_percentage,
                col7: specialData[key].special_type,
                col8: specialData[key].req_buy_amount,
                // col9: specialData[key].free_product_type,
                col9: specialData[key].limit_on_order,
                col10: specialData[key].limit_per_day,
                col11: products,
                col12: free,
                col13: specialData[key].special_on,
                col14: specialData[key].ProductsGroup,
                col15: specialData[key].rules,
                col16: specialData[key].file_url,
                col17: specialData[key].promo_desc,
                col18: specialData[key].BarCode,
            });
        });

        dispatch(
            openModal({
                modalType:
                    activeSpecial.data.discount_type === "1"
                        ? "PromotionDiscount"
                        : "PromotionFree",
                modalProps: { Promotion: specialInfo[0] },
            })
        );
    }

    async function setTotals(totalAmount, itemId) {
        itemId = itemId.trim();

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}update-qty-in-cart`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                StockCode: itemId,
                id: currentUser.id,
                qty: totalAmount,
                customer_code: currentUser.customer_code,
            },
        });

        await getOrder();
        //   let prevItemTotals = JSON.parse(window.sessionStorage.getItem("totalItemsInBasket"));
        //   if(prevItemTotals !== null){
        //     window.sessionStorage.setItem("totalItemsInBasket", JSON.stringify([...prevItemTotals, [totalAmount, itmeId]]));
        //   }else{
        //     window.sessionStorage.setItem("totalItemsInBasket", JSON.stringify([[totalAmount, itmeId]]));
        //   }
    }

    const data = React.useMemo(() => orderItems, [orderItems]);

    const columns = React.useMemo(
        () => [
            {
                Header: "Image",
                accessor: "col1",
                Cell: (tableProps) => (
                    <img
                        src={
                            tableProps.row.original.col15 !== ""
                                ? process.env.REACT_APP_URL +
                                  "media/" +
                                  tableProps.row.original.col15
                                : process.env.REACT_APP_URL +
                                  "/images/placeholder-image.png"
                        }
                        width={150}
                        alt="Product"
                        className="mx-auto px-5 py-5"
                    />
                ),
            },
            {
                Header: "Code",
                accessor: "col2",
                Cell: (rowInfo) => {
                    return (
                        <>
                            {rowInfo.row.original.col14 !== ""
                                ? rowInfo.row.original.col14
                                : rowInfo.row.original.col2}
                        </>
                    );
                },
            },
            {
                Header: "Description",
                accessor: "col3",
            },
            // {
            //     Header: 'Pet Brands',
            //     accessor: 'col23',
            //     Cell: (rowInfo) => {
            //         return (
            //             <>
            //                 {rowInfo.row.original.col23 !== ""
            //                     ? rowInfo.row.original.col23 + " - " + rowInfo.row.original.col24
            //                     : rowInfo.row.original.col23 + " - " + rowInfo.row.original.col24}
            //             </>
            //         );
            //     },
            // },
            {
                Header: "Stock Status",
                accessor: "col4",
                Cell: (rowInfo) => {
                    return (
                        <>
                            {rowInfo.row.original.col4 === "Out of Stock" ? (
                                <p className="text-red-500">
                                    {rowInfo.row.original.col4}{" "}
                                    {currentUser.see_stock === 1 &&
                                    currentUser.supplier_stock !== null &&
                                    allowedSups.includes(
                                        rowInfo.row.original.col26
                                    ) ? (
                                        <>
                                            {"( "}
                                            {rowInfo.row.original.col25}
                                            {" )"}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </p>
                            ) : (
                                <p>
                                    {rowInfo.row.original.col4}{" "}
                                    {currentUser.see_stock === 1 &&
                                    currentUser.supplier_stock !== null &&
                                    allowedSups.includes(
                                        rowInfo.row.original.col26
                                    ) ? (
                                        <>
                                            {"( "}
                                            {rowInfo.row.original.col25}
                                            {" )"}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </p>
                            )}
                            {rowInfo.row.original.col12 === "yes" &&
                            rowInfo.row.original.col4 !== "Out of Stock" ? (
                                <>
                                    <br />
                                    <p
                                        className="bg-red-600 px-2 py-2 text-white text-center cursor-pointer"
                                        onClick={() =>
                                            openPromo(
                                                rowInfo.row.original.col13
                                            )
                                        }
                                    >
                                        On Promotion !
                                    </p>
                                </>
                            ) : (
                                ""
                            )}

                            {/* {rowInfo.row.original.col19 !== "" &&
                    rowInfo.row.original.col12 !== "yes" &&
                    rowInfo.row.original.col4 !== "Out of Stock" ? (
                        <p className="bg-red-600 px-2 py-2 text-white text-center cursor-pointer">
                            On Promotion !
                        </p>
                    ) : (
                        ""
                    )} */}
                        </>
                    );
                },
            },
            {
                Header: "Price Excl. VAT",
                accessor: "col5",
                Cell: (rowInfo) => {
                    return (
                        <>
                            <p>
                                {rowInfo.row.original.col5}

                                {rowInfo.row.original.col10 === "SD" ? (
                                    <>
                                        <span
                                            className="text-green-500 ml-2"
                                            data-tip="Settlement Discount if Applicable"
                                        >
                                            <FontAwesomeIcon
                                                icon={faExclamationCircle}
                                            />
                                        </span>{" "}
                                        <ReactTooltip />
                                    </>
                                ) : (
                                    ""
                                )}
                            </p>
                        </>
                    );
                },
            },
            {
                Header: "QTY",
                accessor: "col6",
                Cell: (rowInfo) => {
                    return (
                        // <Select
                        //   options={[
                        //     { value: "01", label: "01" },
                        //     { value: "02", label: "02" },
                        //     { value: "03", label: "03" }
                        //   ]}
                        //   defaultValue={{ label: rowInfo.row.original.col6, value: rowInfo.row.original.col6 }}
                        //   isSearchable={false}
                        //   onChange={(values) => setTotals(values.value, rowInfo.row.original.col0)}
                        // />

                        <div>
                            {/* {rowInfo.row.original.col4 === "Out of Stock" ?
                                ""
                                : */}
                            <input
                                type="number"
                                min={
                                    rowInfo.row.original.col11 === "yes"
                                        ? "1"
                                        : "0"
                                }
                                defaultValue={Math.floor(
                                    parseFloat(rowInfo.row.original.col6)
                                )}
                                className="shadow-sm text-sm border border_radius_5 w-20"
                                onChange={(values) => {
                                    let mintot =
                                        rowInfo.row.original.col11 === "yes"
                                            ? 1
                                            : 0;
                                    parseFloat(values.target.value) < mintot
                                        ? setTotals(
                                              mintot,
                                              rowInfo.row.original.col2
                                          )
                                        : setTotals(
                                              values.target.value,
                                              rowInfo.row.original.col2
                                          );

                                    if (values.target.value === 0) {
                                        values.target.value = mintot;
                                    } else {
                                        values.target.value = Math.abs(
                                            values.target.value
                                        );
                                    }
                                }}
                            />
                            {/* } */}
                        </div>
                    );
                },
            },
            {
                Header: "",
                accessor: "col8",
                Cell: (rowInfo) => {
                    return (
                        <div>
                            {rowInfo.row.original.col4 === "Out of Stock" ? (
                                <>
                                    {rowInfo.row.original.col9 &&
                                    parseFloat(
                                        rowInfo.row.original.col9.length
                                    ) > 0 ? (
                                        <>
                                            <button
                                                className="px-2 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto"
                                                onClick={() =>
                                                    dispatch(
                                                        openModal({
                                                            modalType:
                                                                "Alternative",
                                                            modalProps: {
                                                                stockCode:
                                                                    rowInfo.row
                                                                        .original
                                                                        .col2,
                                                            },
                                                        })
                                                    )
                                                }
                                            >
                                                See Alternative
                                            </button>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    {/* No Alternative Available */}
                                    {rowInfo.row.original.col4}
                                </>
                            ) : (
                                <>{rowInfo.row.original.col4}</>
                            )}
                        </div>
                    );
                },
            },
            {
                Header: "",
                accessor: "col7",
                Cell: (rowInfo) => {
                    return (
                        <div className="flex">
                            {rowInfo.row.original.col7 === true ? (
                                <FontAwesomeIcon
                                    icon={solidHeart}
                                    className="absolute top-3 right-3 text-xl text-gray-500 cursor-pointer"
                                    onClick={() =>
                                        removeFavourite(
                                            rowInfo.row.original.col2
                                        )
                                    }
                                />
                            ) : (
                                <FontAwesomeIcon
                                    icon={faHeart}
                                    className="absolute top-3 right-3 text-xl text-gray-500 cursor-pointer"
                                    onClick={() =>
                                        addFavourite(rowInfo.row.original.col2)
                                    }
                                />
                            )}

                            {/* {JSON.parse(window.sessionStorage.getItem("itemsIdsInBasket")) !== null && JSON.parse(
                                window.sessionStorage.getItem(
                                    "itemsIdsInBasket"
                                )
                            ).includes(
                                (rowInfo.row.original.col2)
                            ) ? (
                                <>
                                    <button
                                        className="px-4 py-2 mr-5 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto"
                                        onClick={() =>
                                            history.push("/view-cart")
                                        }
                                    >
                                        View Cart
                                    </button>
                                </>
                            ) : (
                                <> */}
                            {/* {rowInfo.row.original.col4 === "Out of Stock" ?
                                <>

                                </>
                                : */}
                            <>
                                <button
                                    className="px-4 py-2 mr-5 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto flex justify-center"
                                    onClick={() =>
                                        addItemToCart(
                                            rowInfo.row.original.col2,
                                            rowInfo.row.original.col6
                                        )
                                    }
                                >
                                    {/* Add to cart */}
                                    {/* + <FontAwesomeIcon icon={faShoppingCart}/> */}
                                    <span className="pr-2 mt-1">+</span>{" "}
                                    <FontAwesomeIcon
                                        icon={faShoppingCart}
                                        className="mt-2"
                                    />
                                </button>
                            </>
                            {/* } */}

                            {rowInfo.row.original.col11 === "yes" ? (
                                <span
                                    className="mt-6 bg-red-500 h-7 px-2 py-1 rounded-full text-white mr-1 min-w-min_red_btn flex justify-center"
                                    data-tip={
                                        rowInfo.row.original.col6 +
                                        " of this item is in your cart"
                                    }
                                >
                                    {rowInfo.row.original.col6}
                                    <ReactTooltip />
                                </span>
                            ) : (
                                <div className="mt-6 h-6 px-2 py-1 rounded-full text-white"></div>
                            )}

                            {/* </>
                            )} */}
                        </div>
                    );
                },
            },
        ],
        []
    );

    const tableInstance = useTable(
        { columns, data, manualPagination: true },
        usePagination
    );

    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
        tableInstance;

    async function repeatThisOrder(orderItems) {
        await Object.keys(orderItems).forEach(async function (key) {
            await addItemToCart(orderItems[key].col0, orderItems[key].col6);
        });

        toast.success("Template Added to Cart", {
            position: "bottom-right",
            autoClose: 5000,
            closeOnClick: true,
        });
    }

    async function deleteThisTemplate(template_id) {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}delete-template`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                template_id: template_id,
                id: currentUser.id,
            },
        });

        history.push("/order-templates");
    }

    async function addItemToCart(itemId, qty) {
        itemId = itemId.trim();

        let cartItem = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}add-items-to-cart`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                StockCode: itemId,
                id: currentUser.id,
                qty: qty,
                auto: 0,
                customer_code: currentUser.customer_code,
            },
        });

        if (cartItem.data == 1) {
            let CurrentCartAmount =
                window.sessionStorage.getItem("itemsInBasket");
            let NewAmount = parseFloat(CurrentCartAmount) + 1;
            window.sessionStorage.setItem("itemsInBasket", NewAmount);
        }

        return "done";
    }

    return (
        <div className="bg-gray-50">
            <AdminSearchForm
                heading="template"
                color="text-gray-500"
                header_color="bg-gradient-to-b from-gray-300"
                crumb_color="text-black"
                breadcrumbs={[
                    { name: "Home", href: "/", count: 1 },
                    {
                        name: "Order Templates",
                        href: "/order-templates",
                        count: 2,
                    },
                    {
                        name: "Template",
                        href: "/template/" + templateID,
                        count: 3,
                    },
                ]}
            />

            <ToastContainer />

            {/* <AdminNav cartTotal={cartTotal}/> */}
            <br />
            <br />

            <div className="container mx-auto relative">
                <p className="text-4xl">
                    Template:{" "}
                    {templateDetails &&
                    templateDetails[0] &&
                    templateDetails[0].template_name
                        ? templateDetails[0].template_name
                        : ""}
                </p>
                <div className="flex absolute top-0 right-0">
                    <button
                        className="inline-flex items-right px-12 py-2 ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_hover  uppercase mt-10"
                        onClick={() =>
                            window.confirm(
                                "Are you sure you wish to delete this item?"
                            ) && deleteThisTemplate(templateID)
                        }
                    >
                        Delete Template
                    </button>
                    <button
                        className="inline-flex items-right px-12 py-2 ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_hover  uppercase mt-10"
                        onClick={() => repeatThisOrder(orderItems)}
                    >
                        repeat order
                    </button>
                </div>
            </div>

            <div className="container mx-auto flex mt-14">
                <table
                    {...getTableProps()}
                    className="mb-10 w-full"
                    style={{
                        borderCollapse: "separate",
                        borderSpacing: "0 1em",
                    }}
                >
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                className="bg-gray-100 bg-gray-100 flex flex-col flex-no wrap md:table-row w-full"
                            >
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps()}
                                        className="lg:px-5 lg:py-5 font-light"
                                    >
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    className="bg-white rounded mt-5 bg-white rounded mt-5 flex flex-col flex-no wrap md:table-row"
                                >
                                    {row.cells.map((cell) => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                className="mx-auto text-center relative lg:border-r md:border-r border-slate-300"
                                            >
                                                {cell.row.original.col12 ===
                                                    "yes" &&
                                                cell.row.original.col4 !==
                                                    "Out of Stock" &&
                                                cell.render("Cell").props.cell
                                                    .column.id === "col1" ? (
                                                    <div
                                                        className="bg-red-600 h-8 w-full absolute top-0 left-0"
                                                        style={{
                                                            clipPath:
                                                                "polygon(0% 0%, 100% 0%, 85% 50%, 100% 100%, 0% 100%)",
                                                        }}
                                                    >
                                                        <p className="px-4 py-1.5 text-white font-bold">
                                                            PROMOTION
                                                        </p>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}

                                                {cell.render("Cell").props.cell
                                                    .column.id === "col2" ||
                                                cell.render("Cell").props.cell
                                                    .column.id === "col4" ? (
                                                    <p className="text-center px-5 py-5 text-gray-500">
                                                        {cell.render("Cell")}
                                                    </p>
                                                ) : (
                                                    cell.render("Cell")
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
