import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/layout/App";
import * as serviceWorker from "./serviceWorker";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { configureStore, history } from "./app/store/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import axios from "axios";
// import ScrollToTop from "./app/layout/ScrollToTop";
import ErrorBoundary from './app/layout/ErrorBoundary';

const { store, persistor } = configureStore();

axios.defaults.withCredentials = true;
const rootEl = document.getElementById("root");

function render() {
    ReactDOM.render(
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ConnectedRouter history={history}>
                    {/* <ScrollToTop /> */}
                    <ErrorBoundary>
                        <App />
                    </ErrorBoundary>
                </ConnectedRouter>
            </PersistGate>
        </Provider>,
        rootEl
    );
}

if (module.hot) {
    module.hot.accept("./app/layout/App", function () {
        setTimeout(render);
    });
}

render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();

