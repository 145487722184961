import { Formik, Form } from "formik";
import axios from "axios";
import * as Yup from 'yup';
import CustomButton from "../../../app/common/form/CustomButton";
import CustomText from "../../../app/common/form/CustomText";
import AdminSearchForm from "../../forms/AdminSearchForm";
import AdminNav from "../../nav/AdminNav";
import { useDispatch, useSelector } from "react-redux";
import CustomTextArea from "../../../app/common/form/CustomTextArea";
import { useState } from "react";
import { useEffect } from "react";
import { openModal } from "../../../app/common/modals/modalReducer";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

export function CustomCodes() {
    const dispatch = useDispatch();
    const { currentUser } = useSelector((state) => state.auth);
    const [codeList, setCodeList] = useState([])
    const [codeLoading, setCodeLoading] = useState(false)

    if(currentUser.multi_account === true && (currentUser.customer_code == null || currentUser.customer_code == "None" || currentUser.customer_code.split(",").length > 1) ){
        window.location.replace('/select-profile')
    }

    useEffect(() => {
        getCodes();
    }, [])

    async function getCodes(){
        let codes = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}get-codes-list`,
            headers: { 
                Authorization: `Bearer ${currentUser.token}`
             },
            data: {userUid: currentUser.id, customer_code: currentUser.customer_code},
        });

        setCodeList(codes.data)
    }

    async function addNewItems(file){
        setCodeLoading(true)

        var formData = new FormData();
        var imagefile = document.querySelector("#importCodes");
        formData.append("file", imagefile.files[0]);
        formData.append("userId", currentUser.id);
        formData.append("customer_code", currentUser.customer_code);

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}import-to-codes`,
            headers: { 
                Authorization: `Bearer ${currentUser.token}`,
                "Content-Type": "multipart/form-data",
                },
            data: formData,
        });

        setCodeLoading(false)
        getCodes();

    }

    async function deleteThisCode(codeId){
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}delete-code`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {codeId: codeId},
        });

        getCodes();
    }


    return (
        <div className="bg-white my-8 space-y-6 px-4 sm md:max-w-2xl lg:max-w-4xl xl:max-w-5xl m-auto">
            <h1 className="text-xl">Custom Codes</h1>

            <div className="container mx-auto relative">
                <div className="flex absolute top-0 right-0">

                    <Link
                        className="inline-flex items-right px-12 py-2 ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_hover  uppercase"
                        as="a"
                        to="/edit_custom_codes/0"
                    >Add New Code</Link>
                    <CustomButton
                        disabled={codeLoading}
                        submitBool={codeLoading}
                        type="submit"
                        text="Import Codes"
                        className="inline-flex items-right px-12 py-2 ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-blue-500 hover:bg-blue-700 uppercase"
                        onClick={() =>
                            document.getElementById("importCodes").click()
                        }
                        data-tip="Stock Code in column A, Custom Code in column B"
                    />
                    <input
                        id="importCodes"
                        name="importCodes"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(event) => {
                            addNewItems(event.target.files[0]);
                        }}
                        className="form-control"
                    />
                    <ReactTooltip />

                </div>
            </div>

            <br/>

            <table style={{width:"100%"}} className="table-auto">
                <tr  className="bg-gray-200 text-left">
                    <th className='px-2 py-2'>Stock Code</th>
                    <th className='px-2 py-2'>My Code</th>
                    <th className='px-2 py-2'>Action</th>
                </tr>
                {codeList.map((code) => {
                    return(
                        <tr>
                            <td className="border border-slate-300 px-5 py-5">{code.stock_code}</td>
                            <td className="border border-slate-300 px-5 py-5">{code.custom_code}</td>
                            <td className="border border-slate-300 px-5 py-5">
                                <Link
                                    className="px-12 py-2 ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_hover"
                                    as="a"
                                    to={"/edit_custom_codes/"+code.id}
                                >Edit</Link>
                                <button className="px-12 py-2 ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-gray-500 hover:bg-gray-600" onClick={() => window.confirm("Are you sure you wish to delete this item?") && deleteThisCode(code.id)}>
                                    Delete
                                </button>
                            </td>
                        </tr>
                    )
                })}

            </table>
            
        </div>
    )    
}