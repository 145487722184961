import React from "react";
import { useField } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";

export default function CustomText({ label, required, ...props }) {
    const [field, meta] = useField(props);

    // change the input class depending if we have a prefix / error
    var inputBorder = "";
    // set field value as empty string if null

    (meta.touched && meta.error) ? inputBorder = "focus:ring-red-500 focus:border-red-500 border-red-500 text-red-500": inputBorder = "focus:ring-gray-500 focus:border-gray-500 border-gray-300";

    return (
        <div className="py-2 w-full">
            <div className={`flex justify-between`}>
                { label && (
                    <label htmlFor={field.name} className="block text-sm font-medium text-gray-700 uppercase mb-5">
                        {label}
                    </label>
                )}
                { required && (
                    <span className="text-sm text-gray-400" id={field.name}>
                        Required
                    </span>
                )}
            </div>
            <div className="mt-1 relative shadow-sm">
                <input
                    {...field} {...props}
                    id={field.name}
                    className={`py-2 px-3  w-full ${inputBorder} `}
                />
                {meta.touched && meta.error && (
                    <div className="absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none">
                        <FontAwesomeIcon className="h-5 w-5 text-red-500" aria-hidden="true" icon={faExclamationCircle}/>
                    </div>
                )}
            </div>
            {meta.touched && meta.error && (
                <p className="mt-2 w-52 text-sm text-red-500" >
                    {meta.error}
                </p>
            )}
        </div>
    );
}
